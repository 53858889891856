@use "../base/variables"

.thumbnail
  border: 1px solid variables.$color-gray
  border-radius: 4px
  height: variables.$thumb-size
  min-width: variables.$thumb-size
  overflow: hidden
  position: relative

  img
    background: variables.$color-gray-light
    height: variables.$thumb-size
    object-fit: contain
    width: variables.$thumb-size
