@use "../../base/mixins"
@use "../../base/variables"

.chart
  &__card
    background-color: variables.$color-white
    border-radius: variables.$border-radius
    color: variables.$color-gray
  
  &__header
    border-bottom: 1px solid variables.$color-gray-light
    min-height: variables.$m
    padding: variables.$m-sm

  &__body
    padding: variables.$m

  &__title
    font-size: 20px

+mixins.media-up(variables.$grid-md) 
  .chart
    display: flex
    flex-wrap: wrap

    &__card
      flex: 0 1 50%

+mixins.media-up(variables.$grid-lg) 
  .chart

    &__card
      flex: 0 1 19%
