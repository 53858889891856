@use "../../base/variables"

.pe
  &__container
    align-items: center
    justify-content: center
    display: flex

  &__card
    align-items: center
    background-color: variables.$color-white
    border-radius: variables.$border-radius
    box-shadow: variables.$box-shadow-simple
    flex: 0 0 290px
    overflow: hidden
    padding: variables.$m-sm
    position: relative
    width: 300px

  &__status
    margin-bottom: variables.$m-xs

  &__video
    display: block
    margin: 0 auto variables.$m-xs
