@use "../base/mixins"
@use "../base/variables"


@keyframes click-me
  0%
    transform: translateY(0)
  10%
    transform: translateY(-4px)
  20%,
  100%
    transform: translateY(0)

@keyframes click-me-shadow
  0%
    +mixins.box-shadow-large(variables.$color-black)
    transform: translateY(0)
  10%
    box-shadow: 0 (variables.$m-xs + 4) variables.$m rgba(variables.$color-black, .2)
    transform: translateY(-4px)
  20%,
  100%
    +mixins.box-shadow-large(variables.$color-black)
    transform: translateY(0)

@keyframes underglow
  0%,
  9.9%
    opacity: 0
    transform: scale(0)
  10%
    opacity: .2
    transform: scale(0)
  50%,
  100%
    opacity: 0
    transform: scale(1.4)
  
.phone 
  align-items: center
  background: variables.$color-black
  border-radius: 40px
  box-shadow: 0 0 70px rgba(variables.$color-black, .2)
  display: flex
  flex-direction: column
  height: 560px
  justify-content: center
  margin: variables.$m auto
  max-width: 320px
  position: relative
  width: 80%

  .top
    align-items: center
    background: variables.$color-black
    border-top-left-radius: 40px
    border-top-right-radius: 40px
    display: flex
    height: 10%
    justify-content: center
    width: 100%

    .sound
      background: gray
      border-radius: variables.$m
      height: 5px
      width: 60px

  .bottom
    align-items: center
    background: variables.$color-black
    border-bottom-left-radius: 40px
    border-bottom-right-radius: 40px
    display: flex
    height: 10%
    justify-content: center
    width: 100%

    .button
      background: #292727
      border-color: variables.$color-white
      border-radius: 50%
      border-style: solid
      border-width: .5px
      box-sizing: border-box
      height: 45px
      width: 45px

  .screen
    height: 80%
    width: 95%
    .logo
      align-items: center
      display: flex
      height: 20%
      justify-content: center
      & img
        max-width: 95%
        max-height: 95%

    .login-button
      align-items: center
      background-color: transparent
      border-radius: variables.$border-radius
      border: 1px solid
      bottom: 115px
      cursor: pointer
      display: flex
      font-size: variables.$font-size-sm
      justify-content: center
      left: variables.$m
      position: absolute
      width: variables.$m-lg
      
    .tutorial
      align-items: center
      animation: help 8s both infinite ease-in-out
      border-radius: 50%
      bottom: 115px
      cursor: pointer
      font-size: variables.$font-size-lg * 1.25
      font-weight: 700
      display: flex
      height: variables.$m-lg
      justify-content: center
      position: absolute
      right: variables.$m
      width: variables.$m-lg

      &__icon
        height: variables.$font-size-sm * 1.25
        margin-top: -2px

        &:before
          font-size: variables.$font-size-sm * 1.25

      &.-no-navbar
        bottom: variables.$m
      
    .button
      align-items: center
      display: flex
      flex-direction: column
      height: 70%
      justify-content: center

      &__label
        align-items: center
        animation: click-me 3s infinite both
        display: flex
        height: 60%
        justify-content: center
        margin-top: 35px
        max-height: 180px
        max-width: 180px
        position: relative
        width: 60%

        &.-round
          animation: click-me-shadow 3s infinite both
          border-radius: 50%
          box-shadow: 0 0 variables.$m grey
      
      &__underglow
        animation: underglow 3s infinite both
        border-radius: 50%
        box-shadow: 0 0 variables.$m variables.$m
        content: ''
        display: block
        height: 60%
        max-height: 180px
        max-width: 180px
        position: absolute
        width: 60%

      &__logo
        max-height: 60%
        max-width: 60%
      &__tap
        font-size: 24px
        font-weight: variables.$font-weight-bold
        letter-spacing: 1px
        margin-top: variables.$m
      &__error
        color: #e62a2a
        text-align: center

    .nav
      align-content: center
      background: variables.$color-white
      box-shadow: 0 0 variables.$m rgba(variables.$color-black, .2)
      color: #5c6366
      display: flex
      font-size: 11px
      font-weight: variables.$font-weight-bold
      height: 10%
      justify-content: space-around
      text-align: center

      &__link
        align-items: center
        box-sizing: border-box
        display: flex

        &.active
          opacity: 1
