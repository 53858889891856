@use "../../base/mixins"
@use "../../base/variables"

.radio-time-ranges
  &__container
    display: flex
    flex-direction: row
    flex-wrap: wrap

    .react-calendar-timeline
      width: 100%

  &__card
    align-items: center
    background-color: variables.$color-white
    border-radius: variables.$border-radius
    box-shadow: variables.$box-shadow-simple
    margin-bottom: variables.$m
    padding: variables.$m-sm
    position: relative

  &__info-item
    color: variables.$color-gray-dark
    margin-bottom: variables.$m-xs
    word-break: break-all

    &.-label
      .title
        color: variables.$color-black
        font-size: variables.$font-size-sm

      .value
        color: variables.$color-gray-dark
        margin-left: variables.$m-xs

        a
          font-size: variables.$font-size-sm

  &__actions
    display: flex
    flex-direction: column
    margin-top: auto
    width: 100%

    a, button
      flex: 1 1 50%
      margin: 3px

    &.-wrap
      flex-wrap: wrap

      a,button
        flex: 1 1 50%

  &__tooltip
    position: relative
    top: -45px
    padding: 0px 10px
    border-radius: 3px
    background: variables.$color-white
    box-shadow: 0 0 5px rgba(0, 0, 0, .4)
    min-width: 136px

    &:after 
      content: " "
      position: absolute
      top: 100%
      left: 50%
      margin-left: -7px
      border-width: 7px
      border-style: solid
      border-color: variables.$color-white transparent transparent transparent

.react-calendar-timeline .rct-sidebar .rct-sidebar-row 
  padding: 0 20px !important

+mixins.media-up(variables.$grid-md)
  .radio-time-ranges

    &__actions
      display: flex
      flex-direction: row
      justify-content: center
      margin-top: auto
      width: 100%

      a, button
        flex: 0 0 70px
        margin: 3px

      &.-wrap
        a, button
          flex: 0 1 48%

+mixins.media-up(variables.$grid-sm)
  .radio-time-ranges
    &__container
      margin-left: auto
      margin-right: auto

    &__card
      margin: variables.$m-xs

    &__actions

      &.-wrap
        a, button
          flex: 0 1 23%
