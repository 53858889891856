@use "variables"

=box-shadow-large($color)
  box-shadow: 0 variables.$m-xs variables.$m rgba($color, .2)

=media($base-width)
  @media screen and (max-width: $base-width)
    @content

=media-up($base-width)
  @media screen and (min-width: $base-width)
    @content

