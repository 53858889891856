@use "../base/variables"

.btn-create
  &__container
    box-shadow: 0 0 10px variables.$color-gray
    align-items: center
    justify-content: center
    background-color: variables.$brand-additional
    color: white
    display: flex
    position: fixed
    right: variables.$m
    bottom: 0
    transform: translateY(-50%)
    width: 45px
    height: 45px
    border-radius: 50%
    z-index: 1100
    &:hover
      color: variables.$color-white
      opacity: 0.9

  &__icon
    width: 20px
    height: 20px

.card-create
  background: variables.$color-gray-light !important
  cursor: pointer
  display: flex
  flex-direction: column !important
  align-items: center !important
  justify-content: center !important
  border: none !important
  box-shadow: none !important
  min-width: 200px

  &:hover
    opacity: 0.9

  &__icon
    color: variables.$brand-additional
    border: 1px solid variables.$brand-additional
    border-width: 2px
    border-radius: 50%
    height: 70px
    width: 70px
