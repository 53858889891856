@use "../base/variables"

.page-header
  align-items: center
  border-bottom: 1px solid #eeeeee
  display: flex
  flex-wrap: wrap
  margin: variables.$m-sm 0
  padding-bottom: 9px

  &__go-back
    color: variables.$color-gray-dark
    cursor: pointer
    height: variables.$m
    margin-left: 5px
    margin-right: variables.$m
    width: variables.$m

    &:hover
      opacity: 0.7

  &__title
    margin: 0

  &__action
    margin-left: auto

.pointer
  cursor: pointer
