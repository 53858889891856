@use "../base/variables"

.map
  height: calc(100vh - #{variables.$layout-size} - #{variables.$m-lg} - #{variables.$m})
  position: relative
  max-width: 2000px
  width: 100%

  &__marker
    align-items: center
    background-color: variables.$color-secondary
    border-color: variables.$color-white
    border-radius: 50px
    border-style: solid
    border-width: 1px
    color: variables.$color-white
    display: flex
    height: 32px
    justify-content: center
    padding: 5px
    text-align: center
    transform: translate(-50%, -50%)
    width: 32px

    &.-small
      height: 45px
      width: 45px
    
    &.-big
      height: 55px
      width: 55px

    &.-large
      height: 65px
      width: 65px

    &:hover
      background-color: variables.$brand-additional
      position: absolute
      transform: scale(2)
      z-index: 100000

  &__pin
    transform: translate(-50%, -100%)

.map-spinner
  left: variables.$m-sm
  position: absolute
  top: variables.$m-sm
  z-index: 1000
