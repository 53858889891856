@use "../../base/mixins"
@use "../../base/variables"

.reports
  display: flex
  flex-wrap: wrap
  justify-content: center

  &__card
    align-items: center
    background-color: variables.$color-white
    border-radius: variables.$border-radius
    box-shadow: variables.$box-shadow-simple
    color: variables.$color-gray-dark
    flex: 0 0 290px
    margin-bottom: variables.$m-sm
    overflow: hidden
    padding: variables.$m-sm
    position: relative
    width: 300px

  &__icon
    height: variables.$m
    width: variables.$m

  &__info-item
    margin-bottom: variables.$m-xs
    word-break: break-all

    &.-datetime
      color: variables.$color-gray
      font-size: variables.$font-size-xs
      margin-top: 0

  &__download
    color: variables.$color-gray-dark

+mixins.media-up(variables.$grid-sm)
  .reports
    &__card
      margin: variables.$m-sm
