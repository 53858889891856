@use "../../base/mixins"
@use "../../base/variables"

.video-time-ranges
  &__container
    display: flex
    flex-direction: row
    flex-wrap: wrap

    .react-calendar-timeline
      width: 100%

  &__card
    align-items: center
    background-color: variables.$color-white
    border-radius: variables.$border-radius
    box-shadow: variables.$box-shadow-simple
    margin-bottom: variables.$m
    padding: variables.$m-sm
    position: relative

  &__info-item
    color: variables.$color-gray-dark
    margin-bottom: variables.$m-xs
    word-break: break-all

    &.-label
      .title
        color: variables.$color-black
        font-size: variables.$font-size-sm

      .value
        color: variables.$color-gray-dark
        margin-left: variables.$m-xs

        a
          font-size: variables.$font-size-sm

  &__actions
    display: flex
    flex-direction: column
    margin-top: auto
    width: 100%

    a, button
      flex: 1 1 50%
      margin: variables.$m-xs / 2 0

    &.-wrap
      flex-wrap: wrap

      a,button
        flex: 1 1 50%

        +mixins.media-up(variables.$grid-md)
  .video-time-ranges

    &__actions
      display: flex
      flex-direction: row
      justify-content: center
      margin-top: auto
      width: 100%

      a, button
        flex: 0 0 70px
        margin-bottom: variables.$m-xs
        margin-right: variables.$m-xs
        margin-top: variables.$m-xs

        &:not(:last-child)
          margin-bottom: variables.$m-xs
          margin-right: variables.$m-xs
          margin-top: variables.$m-xs

      &.-wrap
        a, button
          flex: 0 1 48%

          +mixins.media-up(variables.$grid-sm)
  .video-time-ranges
    &__container
      margin-left: auto
      margin-right: auto

    &__card
      margin: variables.$m-xs

    &__actions

      &.-wrap
        a, button
          flex: 0 1 23%
