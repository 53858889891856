@use "../base/mixins"
@use "../base/variables"

.login
  &__container
    background-color: variables.$color-white
    display: flex
    flex-direction: column
    justify-content: center
    min-height: 100vh
    padding: variables.$m

  &__right-side
    display: none

  &__left-side
    display: flex
    flex-direction: column
    justify-content: center

  &__logo
    max-width: 250px
    margin: variables.$m auto variables.$m-lg

  &__form-name
    font-size: variables.$font-size-xl
    font-weight: variables.$font-weight-regular
    margin-bottom: variables.$m
    text-align: center

  &__form
    margin-left: auto
    margin-right: auto
    max-width: 400px
    padding: variables.$m
    width: 100%

  &__button
    background-color: variables.$brand-third
    border-radius: variables.$border-radius
    border: 0
    color: variables.$color-white
    cursor: pointer
    display: inline-block
    font-weight: variables.$font-weight-bold
    letter-spacing: 1px
    line-height: 1
    padding: variables.$m variables.$m-lg
    text-align: center
    text-decoration: none
    text-transform: uppercase
    transition: background-color 150ms ease
    user-select: none
    vertical-align: middle
    white-space: nowrap

    &:hover
      color: white
      opacity: 0.9
      text-decoration: none

    &.-inner
      margin-top: variables.$m-lg

    &.-login
      background-color: variables.$brand-additional
      margin-top: variables.$m
      width: 100%

+mixins.media-up(variables.$grid-lg) 
  .login
    &__container
      display: flex
      flex-direction: row
      padding: 0

    &__left-side
      flex: 1 0 50%

    &__right-side
      background: url('../../src/assets/stock.jpg')
      background-position: center
      background-repeat: no-repeat
      background-size: cover
      display: inline
      flex: 1 0 50%
      position: relative
  
    &__inner
      margin-left: variables.$m-lg
      position: absolute
      top: 40%
      
    &__inner-title
      color: white
      font-size: 70px
      font-weight: variables.$font-weight-black
      line-height: 1
      text-transform: uppercase
