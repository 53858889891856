@use "sass:color"
@use "../../base/mixins"
@use "../../base/variables"

.pwa_release

  &__list
    display: block

  &__card
    background-color: variables.$color-white
    border-radius: variables.$border-radius
    border: 0
    box-shadow: variables.$box-shadow-simple
    display: flex
    flex-direction: column
    flex: 0 0 290px
    margin-bottom: variables.$m-sm
    overflow: hidden
    position: relative
 
  &__group-item  
    &:hover
        background-color: color.adjust(variables.$color-gray-light, $lightness: -10)
        opacity: 0.8
        text-decoration: none

    &.-active
      background-color: color.adjust(variables.$color-gray-light, $lightness: -20)

  &__details
    display: flex
    flex: 1 1 auto
    flex-direction: column
    padding: variables.$m-sm

  &__action
    margin-top: auto

    a, button
      width: 100%

  &__status
    background-color: variables.$brand-additional
    border-radius: 0 variables.$border-radius variables.$border-radius 0
    color: variables.$color-white
    font-size: variables.$font-size-sm
    font-weight: 100
    left: 0
    padding: 0 variables.$m-sm
    position: absolute
    top: variables.$thumb-size - variables.$m-lg * 0.75

    &.-not-matched
      background-color: variables.$color-danger

    &.-matched
      background-color: variables.$color-success

  &__info-item
    margin-bottom: variables.$m-xs
    word-break: break-all
    
    &.-label
      .title
        color: variables.$color-black
        font-size: variables.$font-size-sm
        font-weight: variables.$font-weight-bold

      .value
        color: variables.$color-gray-dark
        margin-left: variables.$m-xs

    &.-datetime
      color: variables.$color-gray-dark
      font-size: variables.$font-size-xs
      margin-top: 0

+mixins.media-up(variables.$grid-sm)
  .pwa_release
    &__list
      display: flex
      flex-wrap: wrap
    &__card
      width: 33%
      margin: variables.$m-sm

+mixins.media-up(variables.$grid-md)
  .pwa_release
    &__card
      &.-big
        flex: 0 0 590px
        margin: 0
        width: 500px
