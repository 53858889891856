@use "../base/variables"

.badge-round
  background-color: variables.$color-info
  border-radius: variables.$m-sm
  color: variables.$color-white
  display: inline-block
  font-size: variables.$font-size-xs
  font-weight: variables.$font-weight-bold
  padding:  2px variables.$m-xs 2px variables.$m-xs

  &.-danger
    background-color: variables.$color-danger

  &.-success
    background-color: variables.$color-success

  &.-mr
    margin-right: variables.$m-xs

  &.-action
    cursor: pointer

    &:hover
      background-color: variables.$color-danger
