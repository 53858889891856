@use "../base/variables"

.safe-image
  height: variables.$thumb-size
  width: variables.$thumb-size
  position: relative

  &.-sm
    height: variables.$thumb-size / 2
    width: variables.$thumb-size / 2

  img
    top: 50%
    left: 50%
    position: absolute
    object-fit: contain
    transform: translate(-50%, -50%)
    height: variables.$thumb-size
    width: variables.$thumb-size

    &.-sm
      height: variables.$thumb-size / 2 
      width: variables.$thumb-size / 2
