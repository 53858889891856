@use "../../base/mixins"
@use "../../base/variables"

.scan-table
  font-size: variables.$font-size-sm
  line-height: variables.$line-height-sm
  width: 100%

  > thead
    border-bottom: 1px solid variables.$color-gray
    color: variables.$color-gray-dark
    position: sticky
    top: 0

  > tbody:hover
    background-color: variables.$color-white

  td,
  th
    padding: variables.$m-xs

  &.-space-bottom
    margin-bottom: variables.$m
    
  &__status
    border-radius: variables.$border-radius
    color: variables.$color-white
    font:
      size: variables.$font-size-xs
      weight: variables.$font-weight-bold
    letter-spacing: .5px
    padding: variables.$m-xs variables.$m-sm
    text:
      align: center
      transform: uppercase

    &.-not-matched
      background-color: variables.$color-danger

    &.-matched
      background-color: variables.$color-success

  &__status-container
    width: 70px

  &__link
    align-items: center
    color: variables.$brand-additional
    cursor: pointer
    display: flex

  &__icon
    height: variables.$m-sm
    margin-right: variables.$m-xs

.scan-details
  &:not(.-opened)
    display: none

  &__table
    width: 100%

  > td
    padding-top: 0

  th
    font-size: variables.$font-size-xs
    padding-bottom: 0

.scan-list
  display: flex
  flex-wrap: wrap
  justify-content: center

.scan-container
  display: flex
  justify-content: center

.video-scan
  &__status
    background-color: variables.$brand-additional
    border-radius: 0 variables.$border-radius variables.$border-radius 0
    color: variables.$color-white
    font-size: variables.$font-size-sm
    font-weight: 100
    text-align: center
    width: 100px

    &.-not-matched
      background-color: variables.$color-danger

    &.-matched
      background-color: variables.$color-success

.scan
  &__card
    background-color: variables.$color-white
    border-radius: variables.$border-radius
    box-shadow: variables.$box-shadow-simple
    display: flex
    flex: 0 0 290px
    flex-direction: column
    margin-bottom: variables.$m-sm
    overflow: hidden
    position: relative
    width: 300px

  &__images
    display: flex
    justify-content: center

    .safe-image
      background-color: variables.$color-gray-dark
      border: 5px solid variables.$color-gray-dark
      height: variables.$thumb-size
      margin: 0 auto
      width: 100%

    &.-not-matched
      box-shadow: 0 0 15px 1px variables.$color-danger

    &.-matched
      box-shadow: 0 0 15px 1px variables.$color-success

  &__details
    display: flex
    flex: 1 1 auto
    flex-direction: column
    padding: variables.$m-sm

  &__action
    margin-top: auto

    a, button
      width: 100%

  &__status
    background-color: variables.$brand-additional
    border-radius: 0 variables.$border-radius variables.$border-radius 0
    color: variables.$color-white
    font-size: variables.$font-size-sm
    font-weight: 100
    left: 0
    padding: 0 variables.$m-sm
    position: absolute
    top: variables.$thumb-size - variables.$m-lg * 0.75

    &.-not-matched
      background-color: variables.$color-danger

    &.-matched
      background-color: variables.$color-success

  &__info-item
    margin-bottom: variables.$m-xs
    word-break: break-all

    &.-label
      .title
        color: variables.$color-black
        font-size: variables.$font-size-sm
        font-weight: variables.$font-weight-bold

      .value
        color: variables.$color-gray-dark
        margin-left: variables.$m-xs

    &.-datetime
      color: variables.$color-gray-dark
      font-size: variables.$font-size-xs
      margin-top: 0
    
    &.-row-between
      display: flex
      justify-content: space-between

+mixins.media-up(variables.$grid-sm)
  .scan
    &__card
      margin: variables.$m-sm

+mixins.media-up(variables.$grid-md)
  .scan
    &__card
      &.-big
        flex: 0 0 590px
        margin: 0
        width: 500px
