@use "../../base/variables"

.unlock-access
  align-items: center
  display: flex
  flex-direction: column
  justify-content: center
  height: 100vh
  padding: variables.$m
  text-align: center

  &__icon
    color: variables.$color-gray
    margin-bottom: variables.$m-xs
    height: 35px
    width: 35px

  &__button
    margin-top: variables.$m
