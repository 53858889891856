@use "../base/mixins"
@use "../base/variables"

.layout
  &__container
    display: flex
    height: 100vh

  &__menu
    background-color: variables.$brand-secondary
    color: variables.$color-white
    display: flex
    flex: 0 0 variables.$layout-size
    flex-direction: column

  &__menu-header
    background-color: variables.$color-white
    color: variables.$brand-secondary
    display: flex
    flex-direction: column
    font-size: variables.$font-size-sm
    min-height: variables.$layout-size
    justify-content: center
    text-align: center

  &__menu-header-title
    margin: variables.$m-xs / 2

  &__menu-toggler
    display: none

  &__menu-content
    display: flex
    flex-direction: column
    flex: 1
    font-size: variables.$font-size-sm
    letter-spacing: 1px
    overflow: auto
    padding-top: variables.$m

  &__menu-link
    color: variables.$color-white
    padding: variables.$m-sm variables.$m-xs
    text-align: center

    &:hover
      background-color: variables.$brand-third
      color: variables.$color-white
      text-decoration: none

    &:last-child
      margin-top: auto

    &.-active
      color: variables.$brand-third

      &:hover
        color: variables.$color-white

  &__menu-link-icon
    height: variables.$m-sm
    margin-bottom: variables.$m-xs

  &__menu-link-title
    font-size: variables.$font-size-sm
    line-height: variables.$font-size-sm

  &__main
    flex: 1 1 auto
    overflow: auto

  &__main-body
    padding: variables.$m

  &__main-header
    background-color: variables.$color-white
    box-shadow: 0 0 variables.$m-sm variables.$color-gray
    display: flex
    flex-direction: row
    height: variables.$layout-size
    justify-content: space-between
    align-items: center
    position: sticky
    top: 0
    z-index: 1001

  &__timezone
    margin-right: 25px
    &-icon
      width: variables.$m
      margin-right: variables.$m-xs
    &-link
      font-size: 12px
      padding: variables.$m-sm variables.$m-sm
      border-radius: variables.$border-radius
      color: variables.$color-gray-dark
      background-color: transparent
      transition: background-color 400ms
      &:hover
        background-color: variables.$color-gray
        color: variables.$color-black
        text-decoration: none

  &__main-title
    font-size: variables.$font-size-xl
    font-weight: variables.$font-weight-bold
    margin-left: variables.$m

+mixins.media(variables.$grid-sm)
  .layout
    &__menu
      height: 100vh
      left: 0
      position: fixed
      top: 0
      transform: translateX(-100%)
      transition: .3s all
      z-index: 1003

      &.-opened
        box-shadow: 0 0 50px black
        transform: translateX(0)

    &__main-body
      padding: variables.$m-lg variables.$m variables.$m variables.$m

    &__menu-toggler
      background: variables.$brand-third
      border-radius: 50%
      border: 5px variables.$color-white solid
      box-shadow: 0 2px 2px -2px variables.$color-gray
      color: variables.$color-white
      display: inline
      height: 50px
      left: 0px
      padding: 5px
      position: fixed
      top: 75px
      width: 50px
      z-index: 1002

    &__menu-toggler-icon
      height: 15px
      width: 15px
