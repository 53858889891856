@use "../../base/variables"

.files
  &__container
    display: flex
    flex-wrap: wrap
    list-style-type: none
    margin-top: 10px
    padding: 0

    &__wrapper
      margin-bottom: 10px
      margin-right: 10px
      overflow: hidden
      position: relative
      width: 150px

    &__item
      background: variables.$color-gray-light
      border-radius: 4px
      border: 1px solid variables.$color-gray
      display: flex
      height: 150px
      object-fit: contain
      overflow: hidden
      position: relative
      width: 150px

      &--remove
        background: variables.$color-white
        border-radius: 20px
        border: 1px solid variables.$color-danger
        box-shadow: 0 0 5px variables.$color-danger
        color: variables.$color-danger
        cursor: pointer
        height: 25px
        line-height: 1.3
        position: absolute
        right: 10px
        text-align: center
        top: 10px
        width: 25px

      &--error
        color: variables.$color-danger
        font-size: variables.$font-size-xs
