@use "sass:color"
@use "../../base/mixins"
@use "../../base/variables"

.list
  &__actions
    align-items: center
    border-bottom: solid 1px variables.$color-gray
    display: flex
    flex-wrap: wrap
    justify-content: space-between
    margin: variables.$m-sm 0
    padding-bottom: variables.$m-sm

  &__content

    &.-table
      overflow-x: scroll

  &__counter
    cursor: initial !important
    font-weight: variables.$font-weight-regular
    min-width: variables.$layout-size

  &__refetch
    color: variables.$color-gray-dark
    cursor: pointer
    height: variables.$m-sm
    margin-left: variables.$m-sm
    width: variables.$m-sm
    &:hover
      color: color.adjust(variables.$color-gray-dark, $lightness: -20%)

  &__reset
    color: variables.$color-gray
    cursor: pointer
    margin-left: auto
    &:hover
      color: color.adjust(variables.$color-gray, $lightness: -20%)

  &__filters
    background: variables.$color-white
    display: flex
    flex-direction: column
    margin-top: variables.$m-sm
    margin-bottom: variables.$m-sm
    order: 2
    padding: variables.$m
    transition: .3s all

    &.-collapsed
      display: none

  &__filters-toggler
    order: 1

  &__filters-actions
    display: flex
    flex-direction: column

  &__filters-badges-container
    margin-bottom: variables.$m-sm

    &:nth-child
      margin-right: variables.$m-sm

  &__filters-group
    flex: 1 1 30%
    margin-top: variables.$m
    margin-left: variables.$m-xs
    margin-right: variables.$m-xs

  &__filters-group-fields
    border: 1px solid variables.$color-gray
    padding: variables.$m-xs

  &__icon
    height: 14.5px

    &.-right
      margin-left: variables.$m-xs
      margin-right: 0

    &.-left
      margin-right: variables.$m-xs
      margin-left: 0

  &__filter-title
    font-size: variables.$font-size-sm
    font-weight: variables.$font-weight-bold

  &__filter
    margin: variables.$m-sm / 2

  &__filter-switch-container
    display: flex
    justify-content: space-around

  &__filter-switch-label
    align-items: center
    display: inline-flex
    justify-content: center

  &__filter-switch-value
    padding-left: variables.$m-xs
    vertical-align: middle

  &__filter-wrapper
    align-items: center
    display: flex

  &__filter-cancel
    cursor: pointer
    margin-left: variables.$m-sm
    width: variables.$m-sm
    height: variables.$m-sm

    &.-danger
      color: variables.$color-danger

    &.-badge
      margin-left: variables.$m-xs

  &__pages-actions-container
    align-items: center
    display: flex
    flex-direction: column
    justify-content: center
    margin-top: variables.$m-sm

  &__per-page
    align-items: center
    display: flex
    font-size: variables.$font-size-sm
    margin-bottom: variables.$m-sm

  &__per-page-title
    margin-right: variables.$m-sm
    white-space: nowrap

  &__page
    align-items: center
    display: flex
    font-size: variables.$font-size-sm
    margin-bottom: variables.$m-sm
    margin-left: variables.$m-sm
    margin-right: variables.$m-sm

  &__page-title
    margin-right: variables.$m-sm
    white-space: nowrap

  &__page-input
    width: 80px

  &__export-container
    align-items: center
    display: flex
    justify-content: flex-end
    margin-left: variables.$m-sm

  &__export-info
    color: variables.$color-gray-dark
    font-size: variables.$font-size-sm
    margin-left: variables.$m

  &__quick-filters
    background: variables.$color-white
    display: flex
    margin-bottom: variables.$m-sm
    overflow: auto
    text-align: center

  &__quick-filter
    color: variables.$color-gray
    cursor: pointer
    font-size: variables.$font-size-sm
    font-weight: variables.$font-weight-bold
    margin: 0 variables.$m-xs / 2
    padding: variables.$m-sm variables.$m-xs
    text-transform: uppercase
    white-space: nowrap

    &:hover
      color: variables.$brand-additional

    &.-active
      box-shadow: 0 -2px 0 variables.$brand-additional inset
      color: variables.$brand-additional

  &__no-results
    align-items: center
    color: variables.$color-gray
    display: flex
    flex-direction: column
    margin-top: 10%

  &__no-results-card
    align-items: center
    color: variables.$brand-additional
    display: flex
    flex-direction: column
    justify-content: center
    padding: variables.$m-sm
    width: 300px

    &:hover
      opacity: 0.8

  &__no-results-info
    margin-top: variables.$m

  &__no-results-icon
    height: 100px
    width: 100px

+mixins.media-up(variables.$grid-md)
  .list
    &__filters
      flex-direction: row
      justify-content: space-between

    &__search
      margin-left: auto
      width: 300px

    &__filters-group
      margin-top: 0

    &__refetch
      margin-left: variables.$m

    &__export-container
      margin-left: variables.$m

    &__pages-actions-container
      flex-direction: row
      justify-content: flex-end

    &__per-page
      margin-right: variables.$m-sm

    &__page
      margin-right: variables.$m-sm

+mixins.media-up(variables.$grid-sm)
  .list

    &__filters-actions
      align-items: center
      flex-direction: row

    &__filters-toggler
      margin-right: variables.$m-sm
      order: 0

    &__filters-badges-container
      margin: 0

    &__quick-filters
      justify-content: center
