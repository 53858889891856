@use "../base/variables"

.twofactor
  align-items: center
  display: flex
  justify-content: center
  margin: 20px 0
  min-height: 60vh
  width: 100%

  &__wrapper
    background-color: variables.$color-white
    border: 1px solid variables.$color-black
    border-radius: 3px
    
  &__header
    align-items: center
    border: 0
    box-shadow: 0px 1px 0px 0px variables.$color-secondary
    display: flex
    flex-direction: column
    padding: 20px
    text-align: center
    width: 100%

  &__content
    display: flex
    justify-content: center
    padding: 20px 50px

    &__step
      &--qrcode
        display: flex
        justify-content: center
        padding-bottom: 20px

      &--text
        display: flex
        justify-content: center
        padding-bottom: 20px

  &__footer
    box-shadow: 0px -1px 0px 0px variables.$color-secondary
    border-width: 0
    padding: 20px 50px 20px 30px
