.masquarade
  position: relative
  margin: 0
  padding: 0
  display: flex
  flex: 1 1 50%

  &__button
    width: 100%

  &__content
    position: absolute
    min-width: 260px
    top: 0px
    right: 0px
    transform: translate(0px, -103%)
    width: 260px

    &.-hidden
      display: none
      
    &.-shown
      display: flex