@use "../base/variables"

.info-details
  &__row
    &:not(:last-child)
      margin-bottom: variables.$m-xs

    &.-sub
      margin: 0 0 variables.$m-xs variables.$m-sm

    &.-flex
      align-items: center 
      display: flex

  &__label
    font-weight: variables.$font-weight-bold
    margin-right: variables.$m-sm
    text-transform: uppercase

  &__value

    a
      display: block
      font-size: variables.$font-size-sm
      line-height: variables.$line-height-base
      word-break: break-all
      word-wrap: break-word

    .badge:not(:last-child)
      margin-right: variables.$m-xs
