@use "../../base/mixins"
@use "../../base/variables"

.form
  &__container
    background-color: variables.$color-white
    box-shadow: variables.$box-shadow-simple
    margin-bottom: variables.$m
    padding: variables.$m-sm
    border-radius: variables.$border-radius

.form-group
  label
    color: variables.$color-gray-dark
    font-weight: variables.$font-weight-black
    font-size: variables.$font-size-sm
    margin-bottom: variables.$m-xs

.form-check-label
  color: variables.$color-gray-dark
  font-size: variables.$font-size-sm

.custom-file-label
  font-size: variables.$font-size-base !important
  font-weight: variables.$font-weight-light !important

+mixins.media-up(variables.$grid-md)
  .form
    &__container
      margin-left: auto
      margin-right: auto
      width: 70%

      &.-full-width
        width: 100%
  