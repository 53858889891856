@use "../../base/mixins"
@use "../../base/variables"

.object-input
  &__item
    align-items: center
    background: variables.$color-gray-light
    border-radius: variables.$border-radius-sm
    display: flex
    padding: variables.$m/4 0 variables.$m/2
    margin-inline: 0

+mixins.media(variables.$grid-sm)

.object-input
  &__item
    &--remove
      & .btn
        margin-top: 27px
        padding: 6px 15px
