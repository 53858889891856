@use "../../base/mixins"
@use "../../base/variables"

.total-count
  display: flex
  flex-direction: column

  &__card
    background-color: variables.$color-white
    border-radius: variables.$border-radius
    color: variables.$color-gray
    display: flex
    flex-direction: column
    flex: 0 1 19%
    margin-bottom: variables.$m-sm

  &__body
    align-items: center
    display: flex
    flex-direction: column
    justify-content: center
    padding: variables.$m

  &__count
    color: variables.$color-gray-dark
    font-size: 50px
    font-weight: variables.$font-weight-light

  &__title
    font-size: variables.$font-size-base
    justify-content: center

+mixins.media-up(variables.$grid-md) 
  .total-count
    flex-direction: row
    flex-wrap: wrap
    justify-content: space-between
