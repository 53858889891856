@use "../../base/mixins"
@use "../../base/variables"

.latest-scans
  &__brand-name
    color: variables.$color-gray-dark
    font-weight: variables.$font-weight-bold
    margin-left: variables.$m-sm

    &.-table
      margin-left: 0

  &__brand-info
    align-items: center
    border-bottom: 1px solid variables.$color-gray
    display: flex
    height: variables.$font-size-xl + variables.$m-xs
    margin-bottom: variables.$m-sm

    &.-table
      border-bottom: 0

  &__logo
    max-height: variables.$font-size-xl
    max-width: variables.$font-size-xl

+mixins.media-up(variables.$grid-sm)
  .latest-scans
    &__brand-info
      margin-bottom: 0