@use "sass:math"
//----- base -----------------------------------------------------------------------------------------------------------
$font-size-xxl:                   72px
$font-size-xl:                    24px
$font-size-lg:                    20px
$font-size-base:                  15px
$font-size-sm:                    13px
$font-size-xs:                    11px
$icon-size:                       15px

$line-height-base:                1.5
$line-height-lg:                  1.25
$line-height-sm:                  1

$m:                               $font-size-base * $line-height-base
$m-lg:                            $m * 2
$m-sm:                            math.floor($m * 2/3)
$m-xs:                            math.floor($m * 1/3)

$border-radius-lg:                50%
$border-radius:                   $m / 4
$border-radius-sm:                $m / 8

$box-shadow-simple:               0 1px 1px 0 rgba(0,0,0,.1)

$thumb-size:                      150px
$layout-size:                     100px

//----- breakpoints ----------------------------------------------------------------------------------------------------
// Small tablets and large smartphones (landscape view)
$grid-sm:                         576px

// Small tablets (portrait view)
$grid-md:                         768px

// Tablets and small desktops
$grid-lg:                         992px

// Large tablets and desktops
$grid-xl:                         1200px

//----- colors ---------------------------------------------------------------------------------------------------------
$color-secondary:                 #002f52
$color-success:                   #48bd66
$color-warning:                   #ffd200
$color-danger:                    #e62a2a
$color-info:                      #5bc0de
$color-black:                     #04080c
$color-gray-dark:                 hsl(194, 5%, 38%)
$color-gray:                      hsl(194, 5%, 82%)
$color-gray-light:                hsl(194, 5%, 96%)
$color-white:                     #ffffff
$color-list-active:               #e9ecef
$color-list-hover:                #f8f9fa

$brand-primary:                   #007bff
$brand-secondary:                 #0b0825
$brand-third:                     #fb4e09
$brand-additional:                #006fb9

//----- fonts ----------------------------------------------------------------------------------------------------------
$font-weight-light:               300
$font-weight-regular:             400
$font-weight-black:               900
$font-weight-bold:                600

//----- transitions ----------------------------------------------------------------------------------------------------

//----- z-indexes ------------------------------------------------------------------------------------------------------
$z-negative:                      -1
$z-low:                           100
$z-middle:                        200
$z-high:                          300
$z-higher:                        400
