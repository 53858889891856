.layout__container {
  display: flex;
  height: 100vh;
}
.layout__menu {
  background-color: #0b0825;
  color: #ffffff;
  display: flex;
  flex: 0 0 100px;
  flex-direction: column;
}
.layout__menu-header {
  background-color: #ffffff;
  color: #0b0825;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  min-height: 100px;
  justify-content: center;
  text-align: center;
}
.layout__menu-header-title {
  margin: 3.5px;
}
.layout__menu-toggler {
  display: none;
}
.layout__menu-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: 13px;
  letter-spacing: 1px;
  overflow: auto;
  padding-top: 22.5px;
}
.layout__menu-link {
  color: #ffffff;
  padding: 15px 7px;
  text-align: center;
}
.layout__menu-link:hover {
  background-color: #fb4e09;
  color: #ffffff;
  text-decoration: none;
}
.layout__menu-link:last-child {
  margin-top: auto;
}
.layout__menu-link.-active {
  color: #fb4e09;
}
.layout__menu-link.-active:hover {
  color: #ffffff;
}
.layout__menu-link-icon {
  height: 15px;
  margin-bottom: 7px;
}
.layout__menu-link-title {
  font-size: 13px;
  line-height: 13px;
}
.layout__main {
  flex: 1 1 auto;
  overflow: auto;
}
.layout__main-body {
  padding: 22.5px;
}
.layout__main-header {
  background-color: #ffffff;
  box-shadow: 0 0 15px hsl(194, 5%, 82%);
  display: flex;
  flex-direction: row;
  height: 100px;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 1001;
}
.layout__timezone {
  margin-right: 25px;
}
.layout__timezone-icon {
  width: 22.5px;
  margin-right: 7px;
}
.layout__timezone-link {
  font-size: 12px;
  padding: 15px 15px;
  border-radius: 5.625px;
  color: hsl(194, 5%, 38%);
  background-color: transparent;
  transition: background-color 400ms;
}
.layout__timezone-link:hover {
  background-color: hsl(194, 5%, 82%);
  color: #04080c;
  text-decoration: none;
}
.layout__main-title {
  font-size: 24px;
  font-weight: 600;
  margin-left: 22.5px;
}

@media screen and (max-width: 576px) {
  .layout__menu {
    height: 100vh;
    left: 0;
    position: fixed;
    top: 0;
    transform: translateX(-100%);
    transition: 0.3s all;
    z-index: 1003;
  }
  .layout__menu.-opened {
    box-shadow: 0 0 50px black;
    transform: translateX(0);
  }
  .layout__main-body {
    padding: 45px 22.5px 22.5px 22.5px;
  }
  .layout__menu-toggler {
    background: #fb4e09;
    border-radius: 50%;
    border: 5px #ffffff solid;
    box-shadow: 0 2px 2px -2px hsl(194, 5%, 82%);
    color: #ffffff;
    display: inline;
    height: 50px;
    left: 0px;
    padding: 5px;
    position: fixed;
    top: 75px;
    width: 50px;
    z-index: 1002;
  }
  .layout__menu-toggler-icon {
    height: 15px;
    width: 15px;
  }
}
@media screen and (min-width: 720px) {
  .form-container {
    margin: auto;
    max-width: 400px;
  }
}
.form-error {
  color: #dc3545;
  margin-bottom: 1rem;
}

.datetime-picker {
  flex: 1;
  -webkit-appearance: none;
}
.datetime-picker input {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.page-header {
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  display: flex;
  flex-wrap: wrap;
  margin: 15px 0;
  padding-bottom: 9px;
}
.page-header__go-back {
  color: hsl(194, 5%, 38%);
  cursor: pointer;
  height: 22.5px;
  margin-left: 5px;
  margin-right: 22.5px;
  width: 22.5px;
}
.page-header__go-back:hover {
  opacity: 0.7;
}
.page-header__title {
  margin: 0;
}
.page-header__action {
  margin-left: auto;
}

.pointer {
  cursor: pointer;
}

@keyframes click-me {
  0% {
    transform: translateY(0);
  }
  10% {
    transform: translateY(-4px);
  }
  20%, 100% {
    transform: translateY(0);
  }
}
@keyframes click-me-shadow {
  0% {
    box-shadow: 0 7px 22.5px rgba(4, 8, 12, 0.2);
    transform: translateY(0);
  }
  10% {
    box-shadow: 0 11px 22.5px rgba(4, 8, 12, 0.2);
    transform: translateY(-4px);
  }
  20%, 100% {
    box-shadow: 0 7px 22.5px rgba(4, 8, 12, 0.2);
    transform: translateY(0);
  }
}
@keyframes underglow {
  0%, 9.9% {
    opacity: 0;
    transform: scale(0);
  }
  10% {
    opacity: 0.2;
    transform: scale(0);
  }
  50%, 100% {
    opacity: 0;
    transform: scale(1.4);
  }
}
.phone {
  align-items: center;
  background: #04080c;
  border-radius: 40px;
  box-shadow: 0 0 70px rgba(4, 8, 12, 0.2);
  display: flex;
  flex-direction: column;
  height: 560px;
  justify-content: center;
  margin: 22.5px auto;
  max-width: 320px;
  position: relative;
  width: 80%;
}
.phone .top {
  align-items: center;
  background: #04080c;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  display: flex;
  height: 10%;
  justify-content: center;
  width: 100%;
}
.phone .top .sound {
  background: gray;
  border-radius: 22.5px;
  height: 5px;
  width: 60px;
}
.phone .bottom {
  align-items: center;
  background: #04080c;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  display: flex;
  height: 10%;
  justify-content: center;
  width: 100%;
}
.phone .bottom .button {
  background: #292727;
  border-color: #ffffff;
  border-radius: 50%;
  border-style: solid;
  border-width: 0.5px;
  box-sizing: border-box;
  height: 45px;
  width: 45px;
}
.phone .screen {
  height: 80%;
  width: 95%;
}
.phone .screen .logo {
  align-items: center;
  display: flex;
  height: 20%;
  justify-content: center;
}
.phone .screen .logo img {
  max-width: 95%;
  max-height: 95%;
}
.phone .screen .login-button {
  align-items: center;
  background-color: transparent;
  border-radius: 5.625px;
  border: 1px solid;
  bottom: 115px;
  cursor: pointer;
  display: flex;
  font-size: 13px;
  justify-content: center;
  left: 22.5px;
  position: absolute;
  width: 45px;
}
.phone .screen .tutorial {
  align-items: center;
  animation: help 8s both infinite ease-in-out;
  border-radius: 50%;
  bottom: 115px;
  cursor: pointer;
  font-size: 25px;
  font-weight: 700;
  display: flex;
  height: 45px;
  justify-content: center;
  position: absolute;
  right: 22.5px;
  width: 45px;
}
.phone .screen .tutorial__icon {
  height: 16.25px;
  margin-top: -2px;
}
.phone .screen .tutorial__icon:before {
  font-size: 16.25px;
}
.phone .screen .tutorial.-no-navbar {
  bottom: 22.5px;
}
.phone .screen .button {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 70%;
  justify-content: center;
}
.phone .screen .button__label {
  align-items: center;
  animation: click-me 3s infinite both;
  display: flex;
  height: 60%;
  justify-content: center;
  margin-top: 35px;
  max-height: 180px;
  max-width: 180px;
  position: relative;
  width: 60%;
}
.phone .screen .button__label.-round {
  animation: click-me-shadow 3s infinite both;
  border-radius: 50%;
  box-shadow: 0 0 22.5px grey;
}
.phone .screen .button__underglow {
  animation: underglow 3s infinite both;
  border-radius: 50%;
  box-shadow: 0 0 22.5px 22.5px;
  content: "";
  display: block;
  height: 60%;
  max-height: 180px;
  max-width: 180px;
  position: absolute;
  width: 60%;
}
.phone .screen .button__logo {
  max-height: 60%;
  max-width: 60%;
}
.phone .screen .button__tap {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 1px;
  margin-top: 22.5px;
}
.phone .screen .button__error {
  color: #e62a2a;
  text-align: center;
}
.phone .screen .nav {
  align-content: center;
  background: #ffffff;
  box-shadow: 0 0 22.5px rgba(4, 8, 12, 0.2);
  color: #5c6366;
  display: flex;
  font-size: 11px;
  font-weight: 600;
  height: 10%;
  justify-content: space-around;
  text-align: center;
}
.phone .screen .nav__link {
  align-items: center;
  box-sizing: border-box;
  display: flex;
}
.phone .screen .nav__link.active {
  opacity: 1;
}

.list__center {
  align-items: center;
}
.list__item--center {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.thumbnail {
  border: 1px solid hsl(194, 5%, 82%);
  border-radius: 4px;
  height: 150px;
  min-width: 150px;
  overflow: hidden;
  position: relative;
}
.thumbnail img {
  background: hsl(194, 5%, 96%);
  height: 150px;
  object-fit: contain;
  width: 150px;
}

.badge-round {
  background-color: #5bc0de;
  border-radius: 15px;
  color: #ffffff;
  display: inline-block;
  font-size: 11px;
  font-weight: 600;
  padding: 2px 7px 2px 7px;
}
.badge-round.-danger {
  background-color: #e62a2a;
}
.badge-round.-success {
  background-color: #48bd66;
}
.badge-round.-mr {
  margin-right: 7px;
}
.badge-round.-action {
  cursor: pointer;
}
.badge-round.-action:hover {
  background-color: #e62a2a;
}

.info-details__row:not(:last-child) {
  margin-bottom: 7px;
}
.info-details__row.-sub {
  margin: 0 0 7px 15px;
}
.info-details__row.-flex {
  align-items: center;
  display: flex;
}
.info-details__label {
  font-weight: 600;
  margin-right: 15px;
  text-transform: uppercase;
}
.info-details__value a {
  display: block;
  font-size: 13px;
  line-height: 1.5;
  word-break: break-all;
  word-wrap: break-word;
}
.info-details__value .badge:not(:last-child) {
  margin-right: 7px;
}

.safe-image-with-blur {
  height: 150px;
  width: 150px;
  position: relative;
  overflow: hidden;
}
.safe-image-with-blur.-background {
  filter: blur(3px) brightness(80%);
  height: 104%;
  margin-left: -2%;
  margin-top: -2%;
  object-fit: cover;
  min-width: 104%;
  position: absolute;
}
.safe-image-with-blur.-foreground {
  top: 50%;
  left: 50%;
  position: absolute;
  object-fit: contain;
  transform: translate(-50%, -50%);
}

.safe-image {
  height: 150px;
  width: 150px;
  position: relative;
}
.safe-image.-sm {
  height: 75px;
  width: 75px;
}
.safe-image img {
  top: 50%;
  left: 50%;
  position: absolute;
  object-fit: contain;
  transform: translate(-50%, -50%);
  height: 150px;
  width: 150px;
}
.safe-image img.-sm {
  height: 75px;
  width: 75px;
}

.bulk-actions__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 7px 0;
  padding: 7px 0;
}
.bulk-actions__info {
  display: flex;
  margin-bottom: 7px;
  width: 100%;
}
.bulk-actions__actions {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.bulk-actions__actions a, .bulk-actions__actions button {
  margin: 0 0 7px 0;
}

@media screen and (min-width: 576px) {
  .bulk-actions__actions {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
  .bulk-actions__actions a, .bulk-actions__actions button {
    margin: 0 7px 0 0;
  }
}
@media screen and (min-width: 768px) {
  .bulk-actions__container {
    flex-direction: row;
  }
  .bulk-actions__actions {
    justify-content: flex-end;
  }
}
.list__actions {
  align-items: center;
  border-bottom: solid 1px hsl(194, 5%, 82%);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 15px 0;
  padding-bottom: 15px;
}
.list__content.-table {
  overflow-x: scroll;
}
.list__counter {
  cursor: initial !important;
  font-weight: 400;
  min-width: 100px;
}
.list__refetch {
  color: hsl(194, 5%, 38%);
  cursor: pointer;
  height: 15px;
  margin-left: 15px;
  width: 15px;
}
.list__refetch:hover {
  color: hsl(194, 5%, 18%);
}
.list__reset {
  color: hsl(194, 5%, 82%);
  cursor: pointer;
  margin-left: auto;
}
.list__reset:hover {
  color: hsl(194, 5%, 62%);
}
.list__filters {
  background: #ffffff;
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  margin-bottom: 15px;
  order: 2;
  padding: 22.5px;
  transition: 0.3s all;
}
.list__filters.-collapsed {
  display: none;
}
.list__filters-toggler {
  order: 1;
}
.list__filters-actions {
  display: flex;
  flex-direction: column;
}
.list__filters-badges-container {
  margin-bottom: 15px;
}
.list__filters-badges-container:nth-child {
  margin-right: 15px;
}
.list__filters-group {
  flex: 1 1 30%;
  margin-top: 22.5px;
  margin-left: 7px;
  margin-right: 7px;
}
.list__filters-group-fields {
  border: 1px solid hsl(194, 5%, 82%);
  padding: 7px;
}
.list__icon {
  height: 14.5px;
}
.list__icon.-right {
  margin-left: 7px;
  margin-right: 0;
}
.list__icon.-left {
  margin-right: 7px;
  margin-left: 0;
}
.list__filter-title {
  font-size: 13px;
  font-weight: 600;
}
.list__filter {
  margin: 7.5px;
}
.list__filter-switch-container {
  display: flex;
  justify-content: space-around;
}
.list__filter-switch-label {
  align-items: center;
  display: inline-flex;
  justify-content: center;
}
.list__filter-switch-value {
  padding-left: 7px;
  vertical-align: middle;
}
.list__filter-wrapper {
  align-items: center;
  display: flex;
}
.list__filter-cancel {
  cursor: pointer;
  margin-left: 15px;
  width: 15px;
  height: 15px;
}
.list__filter-cancel.-danger {
  color: #e62a2a;
}
.list__filter-cancel.-badge {
  margin-left: 7px;
}
.list__pages-actions-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 15px;
}
.list__per-page {
  align-items: center;
  display: flex;
  font-size: 13px;
  margin-bottom: 15px;
}
.list__per-page-title {
  margin-right: 15px;
  white-space: nowrap;
}
.list__page {
  align-items: center;
  display: flex;
  font-size: 13px;
  margin-bottom: 15px;
  margin-left: 15px;
  margin-right: 15px;
}
.list__page-title {
  margin-right: 15px;
  white-space: nowrap;
}
.list__page-input {
  width: 80px;
}
.list__export-container {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin-left: 15px;
}
.list__export-info {
  color: hsl(194, 5%, 38%);
  font-size: 13px;
  margin-left: 22.5px;
}
.list__quick-filters {
  background: #ffffff;
  display: flex;
  margin-bottom: 15px;
  overflow: auto;
  text-align: center;
}
.list__quick-filter {
  color: hsl(194, 5%, 82%);
  cursor: pointer;
  font-size: 13px;
  font-weight: 600;
  margin: 0 3.5px;
  padding: 15px 7px;
  text-transform: uppercase;
  white-space: nowrap;
}
.list__quick-filter:hover {
  color: #006fb9;
}
.list__quick-filter.-active {
  box-shadow: 0 -2px 0 #006fb9 inset;
  color: #006fb9;
}
.list__no-results {
  align-items: center;
  color: hsl(194, 5%, 82%);
  display: flex;
  flex-direction: column;
  margin-top: 10%;
}
.list__no-results-card {
  align-items: center;
  color: #006fb9;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 15px;
  width: 300px;
}
.list__no-results-card:hover {
  opacity: 0.8;
}
.list__no-results-info {
  margin-top: 22.5px;
}
.list__no-results-icon {
  height: 100px;
  width: 100px;
}

@media screen and (min-width: 768px) {
  .list__filters {
    flex-direction: row;
    justify-content: space-between;
  }
  .list__search {
    margin-left: auto;
    width: 300px;
  }
  .list__filters-group {
    margin-top: 0;
  }
  .list__refetch {
    margin-left: 22.5px;
  }
  .list__export-container {
    margin-left: 22.5px;
  }
  .list__pages-actions-container {
    flex-direction: row;
    justify-content: flex-end;
  }
  .list__per-page {
    margin-right: 15px;
  }
  .list__page {
    margin-right: 15px;
  }
}
@media screen and (min-width: 576px) {
  .list__filters-actions {
    align-items: center;
    flex-direction: row;
  }
  .list__filters-toggler {
    margin-right: 15px;
    order: 0;
  }
  .list__filters-badges-container {
    margin: 0;
  }
  .list__quick-filters {
    justify-content: center;
  }
}
.form__container {
  background-color: #ffffff;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 22.5px;
  padding: 15px;
  border-radius: 5.625px;
}

.form-group label {
  color: hsl(194, 5%, 38%);
  font-weight: 900;
  font-size: 13px;
  margin-bottom: 7px;
}

.form-check-label {
  color: hsl(194, 5%, 38%);
  font-size: 13px;
}

.custom-file-label {
  font-size: 15px !important;
  font-weight: 300 !important;
}

@media screen and (min-width: 768px) {
  .form__container {
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }
  .form__container.-full-width {
    width: 100%;
  }
}
.dropzone {
  flex-direction: column !important;
  text-align: center;
}

.masquarade {
  position: relative;
  margin: 0;
  padding: 0;
  display: flex;
  flex: 1 1 50%;
}
.masquarade__button {
  width: 100%;
}
.masquarade__content {
  position: absolute;
  min-width: 260px;
  top: 0px;
  right: 0px;
  transform: translate(0px, -103%);
  width: 260px;
}
.masquarade__content.-hidden {
  display: none;
}
.masquarade__content.-shown {
  display: flex;
}

.url {
  color: #006fb9;
  cursor: pointer;
  text-decoration: underline;
}

.location {
  align-items: center;
  display: flex;
}
.location__icon {
  height: 15px;
  margin-right: 7px;
}

.redirect-url {
  color: #006fb9;
}
.redirect-url.-button {
  height: 15px;
  margin-right: 7px;
}

.btn-create__container {
  box-shadow: 0 0 10px hsl(194, 5%, 82%);
  align-items: center;
  justify-content: center;
  background-color: #006fb9;
  color: white;
  display: flex;
  position: fixed;
  right: 22.5px;
  bottom: 0;
  transform: translateY(-50%);
  width: 45px;
  height: 45px;
  border-radius: 50%;
  z-index: 1100;
}
.btn-create__container:hover {
  color: #ffffff;
  opacity: 0.9;
}
.btn-create__icon {
  width: 20px;
  height: 20px;
}

.card-create {
  background: hsl(194, 5%, 96%) !important;
  cursor: pointer;
  display: flex;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  border: none !important;
  box-shadow: none !important;
  min-width: 200px;
}
.card-create:hover {
  opacity: 0.9;
}
.card-create__icon {
  color: #006fb9;
  border: 1px solid #006fb9;
  border-width: 2px;
  border-radius: 50%;
  height: 70px;
  width: 70px;
}

.collapse__toggle {
  color: hsl(194, 5%, 38%);
  cursor: pointer;
  border: 1px solid hsl(194, 5%, 38%);
  border-radius: 2.8125px;
  padding: 0 7px;
}
.collapse__toggle-icon {
  height: 15px;
  margin-left: 7px;
  width: 15px;
}

.timezone-select {
  margin-top: 22.5px;
}
.timezone-select select {
  max-width: 250px;
}

.csv-import {
  display: block;
  padding: 22.5px;
  padding-top: 0;
  text-align: center;
}
.csv-import__drop:focus {
  outline: none !important;
}

@media screen and (min-width: 576px) {
  .csv-import__status {
    width: 100%;
  }
}
.google-btn {
  align-self: center;
  background-color: #fcfcfc;
  border-radius: 2px;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2);
  cursor: hand;
  cursor: pointer;
  display: flex;
  height: 42px;
  margin-top: 15px;
  max-width: 204px;
  min-width: 204px;
  transition: all 400ms ease 0s;
  user-select: none;
  width: 80%;
}

.google-btn .google-icon-wrapper {
  border-radius: 2px;
  height: 40px;
  margin-left: 1px;
  margin-top: 1px;
  position: absolute;
  user-select: none;
  width: 40px;
}

.google-btn .google-icon-svg {
  height: 18px;
  margin-left: 11px;
  margin-top: 11px;
  position: absolute;
  user-select: none;
  width: 18px;
}

.google-btn .btn-text {
  color: #757575;
  float: right;
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0.2px;
  margin: 11px 14px 40px 40px;
  user-select: none;
}

.google-btn:hover {
  box-shadow: 0 3px 8px rgba(117, 117, 117, 0.5);
  user-select: none;
}

.google-btn:active {
  background: #F8F8F8;
  box-shadow: 0 1px 1px #757575;
  color: #fff;
  user-select: none;
}

.files__container {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin-top: 10px;
  padding: 0;
}
.files__container__wrapper {
  margin-bottom: 10px;
  margin-right: 10px;
  overflow: hidden;
  position: relative;
  width: 150px;
}
.files__container__item {
  background: hsl(194, 5%, 96%);
  border-radius: 4px;
  border: 1px solid hsl(194, 5%, 82%);
  display: flex;
  height: 150px;
  object-fit: contain;
  overflow: hidden;
  position: relative;
  width: 150px;
}
.files__container__item--remove {
  background: #ffffff;
  border-radius: 20px;
  border: 1px solid #e62a2a;
  box-shadow: 0 0 5px #e62a2a;
  color: #e62a2a;
  cursor: pointer;
  height: 25px;
  line-height: 1.3;
  position: absolute;
  right: 10px;
  text-align: center;
  top: 10px;
  width: 25px;
}
.files__container__item--error {
  color: #e62a2a;
  font-size: 11px;
}

.object-input__item {
  align-items: center;
  background: hsl(194, 5%, 96%);
  border-radius: 2.8125px;
  display: flex;
  padding: 5.625px 0 11.25px;
  margin-inline: 0;
}

.object-input__item--remove .btn {
  margin-top: 27px;
  padding: 6px 15px;
}

.twofactor {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 20px 0;
  min-height: 60vh;
  width: 100%;
}
.twofactor__wrapper {
  background-color: #ffffff;
  border: 1px solid #04080c;
  border-radius: 3px;
}
.twofactor__header {
  align-items: center;
  border: 0;
  box-shadow: 0px 1px 0px 0px #002f52;
  display: flex;
  flex-direction: column;
  padding: 20px;
  text-align: center;
  width: 100%;
}
.twofactor__content {
  display: flex;
  justify-content: center;
  padding: 20px 50px;
}
.twofactor__content__step--qrcode {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}
.twofactor__content__step--text {
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}
.twofactor__footer {
  box-shadow: 0px -1px 0px 0px #002f52;
  border-width: 0;
  padding: 20px 50px 20px 30px;
}

.reference-images__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.reference-images__container .reference-image__card:hover {
  background-color: hsl(194, 5%, 86%);
  opacity: 0.8;
}
.reference-images__container .reference-image__card.-active {
  background-color: hsl(194, 5%, 76%);
}

.reference-image__card {
  align-items: center;
  background-color: #ffffff;
  border-radius: 5.625px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  flex: 0 0 290px;
  margin-bottom: 22.5px;
  overflow: hidden;
  position: relative;
}
.reference-image__card .safe-image {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  height: 150px;
  margin: 0 auto;
  width: 100%;
  min-width: 150px;
}
.reference-image__details {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 15px;
}
.reference-image__type {
  background-color: #006fb9;
  border-radius: 0 5.625px 5.625px 0;
  color: #ffffff;
  font-size: 13px;
  font-weight: 100;
  left: 0;
  padding: 0 15px;
  position: absolute;
  top: 116.25px;
  text-transform: uppercase;
}
.reference-image__type.-pvs {
  background-color: #0b0825;
}
.reference-image__type.-personal_experience {
  background-color: #fb4e09;
}
.reference-image__info {
  margin-bottom: 15px;
  width: 100%;
}
.reference-image__info-item {
  color: hsl(194, 5%, 38%);
  margin-bottom: 7px;
  word-break: break-all;
}
.reference-image__info-item.-label .title {
  color: #04080c;
  font-size: 13px;
}
.reference-image__info-item.-label .value {
  color: hsl(194, 5%, 38%);
  margin-left: 7px;
}
.reference-image__info-item.-label .value a {
  font-size: 13px;
}
.reference-image__info-item.-datetime {
  color: hsl(194, 5%, 82%);
  font-size: 11px;
  margin-top: 0;
}
.reference-image__actions {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  width: 100%;
}
.reference-image__actions a, .reference-image__actions button {
  flex: 1 1 50%;
  margin: 3.5px 0;
}
.reference-image__actions.-wrap {
  flex-wrap: wrap;
}
.reference-image__actions.-wrap a, .reference-image__actions.-wrap button {
  flex: 1 1 50%;
}

@media screen and (min-width: 576px) {
  .reference-image__card {
    align-items: initial;
    display: flex;
    flex-direction: row;
    flex: 0 0 500px;
    justify-content: space-between;
    padding: 22.5px;
  }
  .reference-image__card .safe-image {
    height: 150px;
    margin: 0 22.5px 0 0;
    width: 150px;
  }
  .reference-image__details {
    align-items: initial;
    flex: 1 1 auto;
    padding: 0;
  }
  .reference-image__type {
    top: 143px;
  }
  .reference-image__actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: auto;
    width: 100%;
  }
  .reference-image__actions a, .reference-image__actions button {
    margin-bottom: 0;
  }
  .reference-image__actions a:not(:last-child), .reference-image__actions button:not(:last-child) {
    margin-right: 7px;
  }
  .reference-image__actions.-wrap a, .reference-image__actions.-wrap button {
    flex: 0 1 100%;
    margin-right: 0;
  }
}
@media screen and (min-width: 768px) {
  .reference-image__actions {
    display: flex;
    flex-direction: row;
    margin-top: auto;
    width: 100%;
  }
  .reference-image__actions a, .reference-image__actions button {
    margin-bottom: 0;
  }
  .reference-image__actions a:not(:last-child), .reference-image__actions button:not(:last-child) {
    margin-right: 7px;
  }
  .reference-image__actions.-wrap a, .reference-image__actions.-wrap button {
    flex: 0 1 48%;
  }
}
@media screen and (min-width: 992px) {
  .reference-image__container {
    margin-left: auto;
    margin-right: auto;
    width: 50%;
  }
  .reference-image__card {
    margin: 7px;
  }
  .reference-image__actions a, .reference-image__actions button {
    flex: 0 1 70px;
  }
}
.assets__container {
  background-color: #ffffff;
  margin-left: auto;
  margin-right: auto;
  padding: 15px;
}

.brands__container {
  border-radius: 5.625px;
}
.brands__searchbox {
  margin-bottom: 15px;
}
.brands__list-group {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  border-radius: 5.625px;
}
.brands__list-item {
  list-style: none;
}
.brands__list-item:first-child .brands__item {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.brands__list-item:last-child .brands__item {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.brands__item {
  border-radius: 0.25rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.brands__item .safe-image {
  margin-right: 10px;
}

.brand__logo {
  margin: 7px auto;
  max-width: 86px;
  object-fit: contain;
  object-position: center;
}
.brand__card {
  align-items: center;
  background-color: #ffffff;
  border-radius: 5.625px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  flex: 0 0 290px;
  margin-bottom: 22.5px;
  overflow: hidden;
  position: relative;
}
.brand__card .safe-image {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  height: 150px;
  margin: 0 auto;
  width: 100%;
  min-width: 150px;
  margin-right: 20px;
}
.brand__details {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 15px;
}
.brand__details.-pvs {
  background-color: #0b0825;
}
.brand__details.-personal_experience {
  background-color: #fb4e09;
}
.brand__title {
  font-weight: 900;
}
.brand__info {
  margin-bottom: 15px;
  width: 100%;
}
.brand__info-item {
  margin-bottom: 7px;
  word-break: break-all;
}
.brand__info-item.-label .title {
  color: #04080c;
  font-size: 13px;
  font-weight: 600;
}
.brand__info-item.-label .value {
  color: hsl(194, 5%, 38%);
  margin-left: 7px;
}
.brand__actions {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  width: 100%;
}
.brand__action {
  flex: 1 1 100%;
  margin: 3.5px 0;
}
.brand__switch-ir-service {
  margin: 7px;
  max-width: 500px;
}

@media screen and (min-width: 576px) {
  .brand__card {
    align-items: initial;
    display: flex;
    flex-direction: row;
    flex: 0 0 500px;
    justify-content: space-between;
    padding: 22.5px;
  }
  .brand__card .safe-image {
    height: 150px;
    margin: 0 22.5px 0 0;
    width: 150px;
  }
  .brand__details {
    align-items: initial;
    flex: 1 1 auto;
    padding: 0;
  }
  .brand__type {
    top: 143px;
  }
  .brand__actions {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: auto;
    width: 100%;
  }
  .brand__action {
    flex: 1 1 100%;
    margin-bottom: 3.5px;
  }
}
@media screen and (min-width: 768px) {
  .brands__container {
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }
  .brands__item {
    flex-direction: row;
  }
  .brands__name {
    margin-left: 15px;
  }
  .brand__container {
    margin-left: auto;
    margin-right: auto;
    width: 70%;
  }
  .brand__actions {
    display: flex;
    flex-direction: row;
    margin-top: auto;
    width: 100%;
  }
  .brand__action {
    flex: 0 1 23%;
    margin-right: 7px;
  }
}
@media screen and (min-width: 1200px) {
  .brand__card {
    margin: 7px;
  }
}
.campaigns__container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.campaign__nav {
  display: flex;
  flex-direction: row;
  margin-bottom: 22.5px;
  overflow: auto;
  white-space: nowrap;
}
.campaign__nav-item {
  align-items: center;
  background: hsl(194, 5%, 82%);
  border-top-left-radius: 5.625px;
  border-top-right-radius: 5.625px;
  color: hsl(194, 5%, 38%);
  display: flex;
  margin-right: 7px;
  padding: 7px 15px;
}
.campaign__nav-item:hover {
  cursor: pointer;
  color: #fb4e09;
}
.campaign__nav-item.-active {
  background: #ffffff;
  border-bottom: 2px solid #fb4e09;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  color: #fb4e09;
}
.campaign__nav-text {
  white-space: nowrap;
}
.campaign__nav-icon {
  height: 15px;
  margin-right: 7px;
}
.campaign__card {
  align-items: center;
  background-color: #ffffff;
  border-radius: 5.625px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  flex: 0 0 290px;
  margin-bottom: 22.5px;
  overflow: hidden;
  position: relative;
}
.campaign__card .safe-image {
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  height: 150px;
  margin: 0 auto;
  width: 100%;
  min-width: 150px;
}
.campaign__card.-column {
  flex-direction: column;
}
.campaign__details {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 15px;
}
.campaign__type {
  background-color: #006fb9;
  border-radius: 5.625px;
  color: #ffffff;
  font-size: 13px;
  font-weight: 100;
  padding: 0 15px;
  margin: 2px 15px 2px 0;
  text-transform: uppercase;
  flex-grow: 0;
}
.campaign__type.-pvs {
  background-color: #0b0825;
}
.campaign__type.-personal_experience {
  background-color: #fb4e09;
}
.campaign__types-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
}
.campaign__info {
  margin-bottom: 15px;
  width: 100%;
}
.campaign__info-item {
  color: hsl(194, 5%, 38%);
  margin-bottom: 7px;
  word-break: break-all;
}
.campaign__info-item.-label .title {
  color: #04080c;
  font-size: 13px;
}
.campaign__info-item.-label .title.-danger {
  color: #e62a2a;
}
.campaign__info-item.-label .title.-info {
  color: #5bc0de;
}
.campaign__info-item.-label .value {
  color: hsl(194, 5%, 38%);
  margin-left: 7px;
}
.campaign__info-item.-label .value a {
  font-size: 13px;
}
.campaign__info-item.-datetime {
  color: hsl(194, 5%, 82%);
  font-size: 11px;
  margin-top: 0;
}
.campaign__actions {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  width: 100%;
}
.campaign__actions a, .campaign__actions button {
  flex: 1 1 50%;
  margin: 3.5px 0;
}
.campaign__actions.-wrap {
  flex-wrap: wrap;
}
.campaign__actions.-wrap a, .campaign__actions.-wrap button {
  flex: 1 1 50%;
}

@media screen and (min-width: 576px) {
  .campaign__card {
    align-items: initial;
    display: flex;
    flex-direction: row;
    flex: 0 0 500px;
    justify-content: space-between;
    padding: 22.5px;
  }
  .campaign__card .safe-image {
    height: 150px;
    margin: 0 22.5px 0 0;
    width: 150px;
  }
  .campaign__card.-no-padding {
    padding: 0;
  }
  .campaign__details {
    align-items: initial;
    flex: 1 1 auto;
    padding: 0;
  }
  .campaign__actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: auto;
    width: 100%;
  }
  .campaign__actions a, .campaign__actions button {
    margin-bottom: 0;
  }
  .campaign__actions a:not(:last-child), .campaign__actions button:not(:last-child) {
    margin-right: 7px;
  }
  .campaign__actions.-wrap a, .campaign__actions.-wrap button {
    flex: 0 1 100%;
    margin-right: 0;
  }
}
@media screen and (min-width: 768px) {
  .campaign__actions {
    display: flex;
    flex-direction: row;
    margin-top: auto;
    width: 100%;
  }
  .campaign__actions a, .campaign__actions button {
    margin-bottom: 0;
  }
  .campaign__actions a:not(:last-child), .campaign__actions button:not(:last-child) {
    margin-right: 7px;
  }
  .campaign__actions.-wrap a, .campaign__actions.-wrap button {
    flex: 0 1 48%;
  }
}
@media screen and (min-width: 992px) {
  .campaign__container {
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    max-width: 550px;
  }
  .campaign__card {
    margin: 7px;
  }
  .campaign__actions.-wrap a, .campaign__actions.-wrap button {
    flex: 0 1 70px;
  }
}
.reports {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.reports__card {
  align-items: center;
  background-color: #ffffff;
  border-radius: 5.625px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  color: hsl(194, 5%, 38%);
  flex: 0 0 290px;
  margin-bottom: 15px;
  overflow: hidden;
  padding: 15px;
  position: relative;
  width: 300px;
}
.reports__icon {
  height: 22.5px;
  width: 22.5px;
}
.reports__info-item {
  margin-bottom: 7px;
  word-break: break-all;
}
.reports__info-item.-datetime {
  color: hsl(194, 5%, 82%);
  font-size: 11px;
  margin-top: 0;
}
.reports__download {
  color: hsl(194, 5%, 38%);
}

@media screen and (min-width: 576px) {
  .reports__card {
    margin: 15px;
  }
}
.scan-table {
  font-size: 13px;
  line-height: 1;
  width: 100%;
}
.scan-table > thead {
  border-bottom: 1px solid hsl(194, 5%, 82%);
  color: hsl(194, 5%, 38%);
  position: sticky;
  top: 0;
}
.scan-table > tbody:hover {
  background-color: #ffffff;
}
.scan-table td,
.scan-table th {
  padding: 7px;
}
.scan-table.-space-bottom {
  margin-bottom: 22.5px;
}
.scan-table__status {
  border-radius: 5.625px;
  color: #ffffff;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0.5px;
  padding: 7px 15px;
  text-align: center;
  text-transform: uppercase;
}
.scan-table__status.-not-matched {
  background-color: #e62a2a;
}
.scan-table__status.-matched {
  background-color: #48bd66;
}
.scan-table__status-container {
  width: 70px;
}
.scan-table__link {
  align-items: center;
  color: #006fb9;
  cursor: pointer;
  display: flex;
}
.scan-table__icon {
  height: 15px;
  margin-right: 7px;
}

.scan-details:not(.-opened) {
  display: none;
}
.scan-details__table {
  width: 100%;
}
.scan-details > td {
  padding-top: 0;
}
.scan-details th {
  font-size: 11px;
  padding-bottom: 0;
}

.scan-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.scan-container {
  display: flex;
  justify-content: center;
}

.video-scan__status {
  background-color: #006fb9;
  border-radius: 0 5.625px 5.625px 0;
  color: #ffffff;
  font-size: 13px;
  font-weight: 100;
  text-align: center;
  width: 100px;
}
.video-scan__status.-not-matched {
  background-color: #e62a2a;
}
.video-scan__status.-matched {
  background-color: #48bd66;
}

.scan__card {
  background-color: #ffffff;
  border-radius: 5.625px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex: 0 0 290px;
  flex-direction: column;
  margin-bottom: 15px;
  overflow: hidden;
  position: relative;
  width: 300px;
}
.scan__images {
  display: flex;
  justify-content: center;
}
.scan__images .safe-image {
  background-color: hsl(194, 5%, 38%);
  border: 5px solid hsl(194, 5%, 38%);
  height: 150px;
  margin: 0 auto;
  width: 100%;
}
.scan__images.-not-matched {
  box-shadow: 0 0 15px 1px #e62a2a;
}
.scan__images.-matched {
  box-shadow: 0 0 15px 1px #48bd66;
}
.scan__details {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 15px;
}
.scan__action {
  margin-top: auto;
}
.scan__action a, .scan__action button {
  width: 100%;
}
.scan__status {
  background-color: #006fb9;
  border-radius: 0 5.625px 5.625px 0;
  color: #ffffff;
  font-size: 13px;
  font-weight: 100;
  left: 0;
  padding: 0 15px;
  position: absolute;
  top: 116.25px;
}
.scan__status.-not-matched {
  background-color: #e62a2a;
}
.scan__status.-matched {
  background-color: #48bd66;
}
.scan__info-item {
  margin-bottom: 7px;
  word-break: break-all;
}
.scan__info-item.-label .title {
  color: #04080c;
  font-size: 13px;
  font-weight: 600;
}
.scan__info-item.-label .value {
  color: hsl(194, 5%, 38%);
  margin-left: 7px;
}
.scan__info-item.-datetime {
  color: hsl(194, 5%, 38%);
  font-size: 11px;
  margin-top: 0;
}
.scan__info-item.-row-between {
  display: flex;
  justify-content: space-between;
}

@media screen and (min-width: 576px) {
  .scan__card {
    margin: 15px;
  }
}
@media screen and (min-width: 768px) {
  .scan__card.-big {
    flex: 0 0 590px;
    margin: 0;
    width: 500px;
  }
}
.pwa_release__list {
  display: block;
}
.pwa_release__card {
  background-color: #ffffff;
  border-radius: 5.625px;
  border: 0;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  flex: 0 0 290px;
  margin-bottom: 15px;
  overflow: hidden;
  position: relative;
}
.pwa_release__group-item:hover {
  background-color: hsl(194, 5%, 86%);
  opacity: 0.8;
  text-decoration: none;
}
.pwa_release__group-item.-active {
  background-color: hsl(194, 5%, 76%);
}
.pwa_release__details {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 15px;
}
.pwa_release__action {
  margin-top: auto;
}
.pwa_release__action a, .pwa_release__action button {
  width: 100%;
}
.pwa_release__status {
  background-color: #006fb9;
  border-radius: 0 5.625px 5.625px 0;
  color: #ffffff;
  font-size: 13px;
  font-weight: 100;
  left: 0;
  padding: 0 15px;
  position: absolute;
  top: 116.25px;
}
.pwa_release__status.-not-matched {
  background-color: #e62a2a;
}
.pwa_release__status.-matched {
  background-color: #48bd66;
}
.pwa_release__info-item {
  margin-bottom: 7px;
  word-break: break-all;
}
.pwa_release__info-item.-label .title {
  color: #04080c;
  font-size: 13px;
  font-weight: 600;
}
.pwa_release__info-item.-label .value {
  color: hsl(194, 5%, 38%);
  margin-left: 7px;
}
.pwa_release__info-item.-datetime {
  color: hsl(194, 5%, 38%);
  font-size: 11px;
  margin-top: 0;
}

@media screen and (min-width: 576px) {
  .pwa_release__list {
    display: flex;
    flex-wrap: wrap;
  }
  .pwa_release__card {
    width: 33%;
    margin: 15px;
  }
}
@media screen and (min-width: 768px) {
  .pwa_release__card.-big {
    flex: 0 0 590px;
    margin: 0;
    width: 500px;
  }
}
.login__container {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  padding: 22.5px;
}
.login__right-side {
  display: none;
}
.login__left-side {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.login__logo {
  max-width: 250px;
  margin: 22.5px auto 45px;
}
.login__form-name {
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 22.5px;
  text-align: center;
}
.login__form {
  margin-left: auto;
  margin-right: auto;
  max-width: 400px;
  padding: 22.5px;
  width: 100%;
}
.login__button {
  background-color: #fb4e09;
  border-radius: 5.625px;
  border: 0;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-weight: 600;
  letter-spacing: 1px;
  line-height: 1;
  padding: 22.5px 45px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 150ms ease;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;
}
.login__button:hover {
  color: white;
  opacity: 0.9;
  text-decoration: none;
}
.login__button.-inner {
  margin-top: 45px;
}
.login__button.-login {
  background-color: #006fb9;
  margin-top: 22.5px;
  width: 100%;
}

@media screen and (min-width: 992px) {
  .login__container {
    display: flex;
    flex-direction: row;
    padding: 0;
  }
  .login__left-side {
    flex: 1 0 50%;
  }
  .login__right-side {
    background: url("../../src/assets/stock.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: inline;
    flex: 1 0 50%;
    position: relative;
  }
  .login__inner {
    margin-left: 45px;
    position: absolute;
    top: 40%;
  }
  .login__inner-title {
    color: white;
    font-size: 70px;
    font-weight: 900;
    line-height: 1;
    text-transform: uppercase;
  }
}
.pe__container {
  align-items: center;
  justify-content: center;
  display: flex;
}
.pe__card {
  align-items: center;
  background-color: #ffffff;
  border-radius: 5.625px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  flex: 0 0 290px;
  overflow: hidden;
  padding: 15px;
  position: relative;
  width: 300px;
}
.pe__status {
  margin-bottom: 7px;
}
.pe__video {
  display: block;
  margin: 0 auto 7px;
}

.unlock-access {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  padding: 22.5px;
  text-align: center;
}
.unlock-access__icon {
  color: hsl(194, 5%, 82%);
  margin-bottom: 7px;
  height: 35px;
  width: 35px;
}
.unlock-access__button {
  margin-top: 22.5px;
}

.video-time-ranges__container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.video-time-ranges__container .react-calendar-timeline {
  width: 100%;
}
.video-time-ranges__card {
  align-items: center;
  background-color: #ffffff;
  border-radius: 5.625px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 22.5px;
  padding: 15px;
  position: relative;
}
.video-time-ranges__info-item {
  color: hsl(194, 5%, 38%);
  margin-bottom: 7px;
  word-break: break-all;
}
.video-time-ranges__info-item.-label .title {
  color: #04080c;
  font-size: 13px;
}
.video-time-ranges__info-item.-label .value {
  color: hsl(194, 5%, 38%);
  margin-left: 7px;
}
.video-time-ranges__info-item.-label .value a {
  font-size: 13px;
}
.video-time-ranges__actions {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  width: 100%;
}
.video-time-ranges__actions a, .video-time-ranges__actions button {
  flex: 1 1 50%;
  margin: 3.5px 0;
}
.video-time-ranges__actions.-wrap {
  flex-wrap: wrap;
}
.video-time-ranges__actions.-wrap a, .video-time-ranges__actions.-wrap button {
  flex: 1 1 50%;
}
.video-time-ranges .video-time-ranges__actions {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: auto;
  width: 100%;
}
.video-time-ranges .video-time-ranges__actions a, .video-time-ranges .video-time-ranges__actions button {
  flex: 0 0 70px;
  margin-bottom: 7px;
  margin-right: 7px;
  margin-top: 7px;
}
.video-time-ranges .video-time-ranges__actions a:not(:last-child), .video-time-ranges .video-time-ranges__actions button:not(:last-child) {
  margin-bottom: 7px;
  margin-right: 7px;
  margin-top: 7px;
}
.video-time-ranges .video-time-ranges__actions.-wrap a, .video-time-ranges .video-time-ranges__actions.-wrap button {
  flex: 0 1 48%;
}
.video-time-ranges .video-time-ranges__container {
  margin-left: auto;
  margin-right: auto;
}
.video-time-ranges .video-time-ranges__card {
  margin: 7px;
}
.video-time-ranges .video-time-ranges__actions.-wrap a, .video-time-ranges .video-time-ranges__actions.-wrap button {
  flex: 0 1 23%;
}

.radio-time-ranges__container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.radio-time-ranges__container .react-calendar-timeline {
  width: 100%;
}
.radio-time-ranges__card {
  align-items: center;
  background-color: #ffffff;
  border-radius: 5.625px;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 22.5px;
  padding: 15px;
  position: relative;
}
.radio-time-ranges__info-item {
  color: hsl(194, 5%, 38%);
  margin-bottom: 7px;
  word-break: break-all;
}
.radio-time-ranges__info-item.-label .title {
  color: #04080c;
  font-size: 13px;
}
.radio-time-ranges__info-item.-label .value {
  color: hsl(194, 5%, 38%);
  margin-left: 7px;
}
.radio-time-ranges__info-item.-label .value a {
  font-size: 13px;
}
.radio-time-ranges__actions {
  display: flex;
  flex-direction: column;
  margin-top: auto;
  width: 100%;
}
.radio-time-ranges__actions a, .radio-time-ranges__actions button {
  flex: 1 1 50%;
  margin: 3px;
}
.radio-time-ranges__actions.-wrap {
  flex-wrap: wrap;
}
.radio-time-ranges__actions.-wrap a, .radio-time-ranges__actions.-wrap button {
  flex: 1 1 50%;
}
.radio-time-ranges__tooltip {
  position: relative;
  top: -45px;
  padding: 0px 10px;
  border-radius: 3px;
  background: #ffffff;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
  min-width: 136px;
}
.radio-time-ranges__tooltip:after {
  content: " ";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -7px;
  border-width: 7px;
  border-style: solid;
  border-color: #ffffff transparent transparent transparent;
}

.react-calendar-timeline .rct-sidebar .rct-sidebar-row {
  padding: 0 20px !important;
}

@media screen and (min-width: 768px) {
  .radio-time-ranges__actions {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: auto;
    width: 100%;
  }
  .radio-time-ranges__actions a, .radio-time-ranges__actions button {
    flex: 0 0 70px;
    margin: 3px;
  }
  .radio-time-ranges__actions.-wrap a, .radio-time-ranges__actions.-wrap button {
    flex: 0 1 48%;
  }
}
@media screen and (min-width: 576px) {
  .radio-time-ranges__container {
    margin-left: auto;
    margin-right: auto;
  }
  .radio-time-ranges__card {
    margin: 7px;
  }
  .radio-time-ranges__actions.-wrap a, .radio-time-ranges__actions.-wrap button {
    flex: 0 1 23%;
  }
}
.latest-scans__brand-name {
  color: hsl(194, 5%, 38%);
  font-weight: 600;
  margin-left: 15px;
}
.latest-scans__brand-name.-table {
  margin-left: 0;
}
.latest-scans__brand-info {
  align-items: center;
  border-bottom: 1px solid hsl(194, 5%, 82%);
  display: flex;
  height: 31px;
  margin-bottom: 15px;
}
.latest-scans__brand-info.-table {
  border-bottom: 0;
}
.latest-scans__logo {
  max-height: 24px;
  max-width: 24px;
}

@media screen and (min-width: 576px) {
  .latest-scans__brand-info {
    margin-bottom: 0;
  }
}
.chart__card {
  background-color: #ffffff;
  border-radius: 5.625px;
  color: hsl(194, 5%, 82%);
}
.chart__header {
  border-bottom: 1px solid hsl(194, 5%, 96%);
  min-height: 22.5px;
  padding: 15px;
}
.chart__body {
  padding: 22.5px;
}
.chart__title {
  font-size: 20px;
}

@media screen and (min-width: 768px) {
  .chart {
    display: flex;
    flex-wrap: wrap;
  }
  .chart__card {
    flex: 0 1 50%;
  }
}
@media screen and (min-width: 992px) {
  .chart__card {
    flex: 0 1 19%;
  }
}
.total-count {
  display: flex;
  flex-direction: column;
}
.total-count__card {
  background-color: #ffffff;
  border-radius: 5.625px;
  color: hsl(194, 5%, 82%);
  display: flex;
  flex-direction: column;
  flex: 0 1 19%;
  margin-bottom: 15px;
}
.total-count__body {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 22.5px;
}
.total-count__count {
  color: hsl(194, 5%, 38%);
  font-size: 50px;
  font-weight: 300;
}
.total-count__title {
  font-size: 15px;
  justify-content: center;
}

@media screen and (min-width: 768px) {
  .total-count {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.map {
  height: calc(100vh - 100px - 45px - 22.5px);
  position: relative;
  max-width: 2000px;
  width: 100%;
}
.map__marker {
  align-items: center;
  background-color: #002f52;
  border-color: #ffffff;
  border-radius: 50px;
  border-style: solid;
  border-width: 1px;
  color: #ffffff;
  display: flex;
  height: 32px;
  justify-content: center;
  padding: 5px;
  text-align: center;
  transform: translate(-50%, -50%);
  width: 32px;
}
.map__marker.-small {
  height: 45px;
  width: 45px;
}
.map__marker.-big {
  height: 55px;
  width: 55px;
}
.map__marker.-large {
  height: 65px;
  width: 65px;
}
.map__marker:hover {
  background-color: #006fb9;
  position: absolute;
  transform: scale(2);
  z-index: 100000;
}
.map__pin {
  transform: translate(-50%, -100%);
}

.map-spinner {
  left: 15px;
  position: absolute;
  top: 15px;
  z-index: 1000;
}

body {
  background-color: hsl(194, 5%, 96%);
  font-family: "Open Sans", sans-serif;
}

nav {
  box-shadow: 0 0 10px 0 hsl(194, 5%, 82%);
}

.btn-primary {
  background-color: #006fb9;
  border-color: #006fb9;
}
.btn-primary:hover, .btn-primary:active {
  background-color: rgb(0, 95.7, 159.5);
}

.custom-file-label {
  overflow: hidden;
  white-space: nowrap;
}

img {
  max-width: 100%;
}

.list-group-scrollable {
  max-height: 600px;
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

.icon {
  height: 15px;
  width: 15px;
}
.icon.-active:hover {
  opacity: 0.8;
}

.break-line {
  width: 100%;
}

.-box-shadow-0 {
  box-shadow: none;
}

.m-0-auto {
  margin: 0 auto;
}

.pointer {
  cursor: pointer;
}

a {
  color: #006fb9;
}

.page-item a {
  color: #006fb9 !important;
}
.page-item.disabled a {
  color: hsl(194, 5%, 38%) !important;
}
.page-item.active a {
  background-color: #006fb9 !important;
  color: #ffffff !important;
}

.spinner-page {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 100vh;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}
.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

.label-hidden label {
  display: none !important;
}

.badge-secondary {
  color: #002f52;
}
.badge-success {
  color: #48bd66;
}
.badge-info {
  color: #5bc0de;
}
.badge-danger {
  color: #e62a2a;
}
.badge-warning {
  color: #ffd200;
}

