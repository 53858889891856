@use "../base/variables"


.google-btn 
    align-self: center
    background-color: #fcfcfc
    border-radius: 2px
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .2)
    cursor: hand
    cursor: pointer
    display: flex
    height: 42px
    margin-top: variables.$m-sm
    max-width: 204px
    min-width: 204px
    transition: all 400ms ease 0s
    user-select: none
    width: 80%

.google-btn .google-icon-wrapper 
    border-radius: 2px
    height: 40px
    margin-left: 1px
    margin-top: 1px
    position: absolute
    user-select: none
    width: 40px

.google-btn .google-icon-svg 
    height: 18px
    margin-left: 11px
    margin-top: 11px
    position: absolute
    user-select: none
    width: 18px

.google-btn .btn-text 
    color: #757575
    float: right
    font-family: Roboto
    font-size: 14px
    letter-spacing: .2px
    margin: 11px 14px 40px 40px
    user-select: none

.google-btn:hover 
    box-shadow: 0 3px 8px rgba(117, 117, 117, .5)
    user-select: none

.google-btn:active 
    background: #F8F8F8
    box-shadow: 0 1px 1px #757575
    color: #fff
    user-select: none
