@use "sass:color"
@use 'base/_variables.sass'
@use 'base/_mixins.sass'

@use 'layout/layout'

@use 'components/form.sass'
@use 'components/header.sass'
@use 'components/pwa-preview.sass'
@use 'components/list.sass'
@use 'components/thumbnail.sass'
@use 'components/badge-round.sass'
@use 'components/info-details.sass'
@use 'components/safe-image-with-blur.sass'
@use 'components/safe-image.sass'
@use 'components/list/bulk-actions.sass'
@use 'components/list/list.sass' as list2
@use 'components/form/index.sass'
@use 'components/form/dropzone.sass'
@use 'components/masquarade.sass'
@use 'components/url.sass'
@use 'components/location-button.sass'
@use 'components/redirect-url-button.sass'
@use 'components/button-create.sass'
@use 'components/collapse.sass'
@use 'components/timezone-select.sass'
@use 'components/csv-import.sass'
@use 'components/oauth-google.sass'

@use 'components/form/multi-image-form.sass'
@use 'components/form/object-input.sass'

@use 'containers/two-factor-auth.sass'
@use 'containers/referenceImage/list.sass' as list3
@use 'containers/pages/assets.sass'
@use 'containers/brand/details.sass'
@use 'containers/campaign/details.sass' as details2
@use 'containers/report/list.sass' as list4
@use 'containers/scan/details.sass' as details3
@use 'containers/pwaRelease/details.sass' as details4
@use 'containers/login.sass'
@use 'containers/personalExperiences/details.sass' as details5
@use 'containers/unlockAccess/index.sass' as index2
@use 'containers/videoTimeRange/details.sass' as details6
@use 'containers/radioTimeRange/details.sass' as details7
@use 'containers/latestScan/details.sass' as details8
@use 'containers/stats/chart'
@use 'containers/stats/total-count'
@use 'containers/map'

body
  background-color: variables.$color-gray-light
  font-family: 'Open Sans', sans-serif

nav
  box-shadow: 0 0 10px 0 variables.$color-gray

.btn-primary
  background-color: variables.$brand-additional
  border-color: variables.$brand-additional

  &:hover, &:active
    background-color: color.adjust(variables.$brand-additional, $lightness: -5%)

.custom-file-label
  overflow: hidden
  white-space: nowrap

img
  max-width: 100%

.list-group-scrollable
  max-height: 600px
  overflow: scroll
  -webkit-overflow-scrolling: touch

.icon
  height: variables.$icon-size
  width: variables.$icon-size

  &.-active
    &:hover
      opacity: 0.8

.break-line
  width: 100%

.-box-shadow-0
  box-shadow: none

.m-0-auto
  margin: 0 auto

.pointer
  cursor: pointer

a
  color: variables.$brand-additional

.page-item
  a
    color: variables.$brand-additional !important

  &.disabled
    a
      color: variables.$color-gray-dark !important

  &.active

    a
      background-color: variables.$brand-additional !important
      color: variables.$color-white !important

.spinner-page
  align-items: center
  display: flex
  justify-content: center
  min-height: 100vh

.sr-only
  position: absolute
  width: 1px
  height: 1px
  padding: 0
  margin: -1px
  overflow: hidden
  clip: rect(0, 0, 0, 0)
  border: 0
  &-focusable:active, &-focusable:focus
    position: static
    width: auto
    height: auto
    margin: 0
    overflow: visible
    clip: auto

.label-hidden
  label
    display: none !important

.badge
  &-secondary
    color: variables.$color-secondary
  &-success
    color: variables.$color-success
  &-info
    color: variables.$color-info
  &-danger
    color: variables.$color-danger
  &-warning
    color: variables.$color-warning
