@use "../../base/mixins"
@use "../../base/variables"

.campaigns
  &__container
    display: flex
    flex-wrap: wrap
    justify-content: center

.campaign
  &__nav
    display: flex
    flex-direction: row
    margin-bottom: variables.$m
    overflow: auto
    white-space: nowrap

  &__nav-item
    align-items: center
    background: variables.$color-gray
    border-top-left-radius: variables.$border-radius
    border-top-right-radius: variables.$border-radius
    color: variables.$color-gray-dark
    display: flex
    margin-right: variables.$m-xs
    padding: variables.$m-xs variables.$m-sm

    &:hover
      cursor: pointer
      color: variables.$brand-third

    &.-active
      background: variables.$color-white
      border-bottom: 2px solid variables.$brand-third
      box-shadow: variables.$box-shadow-simple
      color: variables.$brand-third

  &__nav-text
    white-space: nowrap

  &__nav-icon
    height: variables.$m-sm
    margin-right: variables.$m-xs

  &__card
    align-items: center
    background-color: variables.$color-white
    border-radius: variables.$border-radius
    box-shadow: variables.$box-shadow-simple
    flex: 0 0 290px
    margin-bottom: variables.$m
    overflow: hidden
    position: relative

    .safe-image
      box-shadow: variables.$box-shadow-simple
      height: variables.$thumb-size
      margin: 0 auto
      width: 100%
      min-width: variables.$thumb-size

    &.-column
      flex-direction: column

  &__details
    align-items: center
    display: flex
    flex-direction: column
    padding: variables.$m-sm

  &__type
    background-color: variables.$brand-additional
    border-radius: variables.$border-radius
    color: variables.$color-white
    font-size: variables.$font-size-sm
    font-weight: 100
    padding: 0 variables.$m-sm
    margin: 2px variables.$m-sm 2px 0
    text-transform: uppercase
    flex-grow: 0

    &.-pvs
      background-color: variables.$brand-secondary

    &.-personal_experience
      background-color: variables.$brand-third

  &__types-container
    display: flex
    flex-wrap: wrap
    gap: 2px

  &__info
    margin-bottom: variables.$m-sm
    width: 100%

  &__info-item
    color: variables.$color-gray-dark
    margin-bottom: variables.$m-xs
    word-break: break-all

    &.-label
      .title
        color: variables.$color-black
        font-size: variables.$font-size-sm

        &.-danger
          color: variables.$color-danger

        &.-info
          color: variables.$color-info

      .value
        color: variables.$color-gray-dark
        margin-left: variables.$m-xs

        a
          font-size: variables.$font-size-sm

    &.-datetime
      color: variables.$color-gray
      font-size: variables.$font-size-xs
      margin-top: 0

  &__actions
    display: flex
    flex-direction: column
    margin-top: auto
    width: 100%

    a, button
      flex: 1 1 50%
      margin: variables.$m-xs / 2 0

    &.-wrap
      flex-wrap: wrap

      a,button
        flex: 1 1 50%

+mixins.media-up(variables.$grid-sm)
  .campaign

    &__card
      align-items: initial
      display: flex
      flex-direction: row
      flex: 0 0 500px
      justify-content: space-between
      padding: variables.$m

      .safe-image
        height: variables.$thumb-size
        margin: 0 variables.$m 0 0
        width: variables.$thumb-size

      &.-no-padding
        padding: 0

    &__details
      align-items: initial
      flex: 1 1 auto
      padding: 0

    &__actions
      display: flex
      flex-direction: row
      justify-content: flex-start
      margin-top: auto
      width: 100%

      a, button
        margin-bottom: 0

        &:not(:last-child)
          margin-right: variables.$m-xs

      &.-wrap
        a, button
          flex: 0 1 100%
          margin-right: 0

+mixins.media-up(variables.$grid-md)
  .campaign

    &__actions
      display: flex
      flex-direction: row
      margin-top: auto
      width: 100%

      a, button
        margin-bottom: 0

        &:not(:last-child)
          margin-right: variables.$m-xs

      &.-wrap
        a, button
          flex: 0 1 48%

+mixins.media-up(variables.$grid-lg)
  .campaign

    &__container
      margin-left: auto
      margin-right: auto
      width: 70%
      max-width: 550px

    &__card
      margin: variables.$m-xs

    &__actions

      &.-wrap
        a, button
          flex: 0 1 70px
