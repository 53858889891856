@use "../../base/mixins"
@use "../../base/variables"

.brands
  &__container
    border-radius: variables.$border-radius

  &__searchbox
    margin-bottom: variables.$m-sm

  &__list-group 
    box-shadow: variables.$box-shadow-simple
    border-radius: variables.$border-radius

  &__list-item
    list-style: none

    &:first-child .brands__item
      border-bottom-left-radius: 0
      border-bottom-right-radius: 0

    &:last-child .brands__item
      border-top-left-radius: 0
      border-top-right-radius: 0

  &__item
    border-radius: 0.25rem
    display: flex
    flex-direction: row
    align-items: center

    & .safe-image
      margin-right: 10px

.brand
  &__logo
    margin: variables.$m-xs auto
    max-width: variables.$layout-size - 2 * variables.$m-xs
    object-fit: contain
    object-position: center

  &__card
    align-items: center
    background-color: variables.$color-white
    border-radius: variables.$border-radius
    box-shadow: variables.$box-shadow-simple
    flex: 0 0 290px
    margin-bottom: variables.$m
    overflow: hidden
    position: relative

    .safe-image
      box-shadow: variables.$box-shadow-simple
      height: variables.$thumb-size
      margin: 0 auto
      width: 100%
      min-width: variables.$thumb-size
      margin-right: 20px

  &__details
    align-items: center
    display: flex
    flex-direction: column
    padding: variables.$m-sm

    &.-pvs
      background-color: variables.$brand-secondary

    &.-personal_experience
      background-color: variables.$brand-third

  &__title
    font-weight: variables.$font-weight-black

  &__info
    margin-bottom: variables.$m-sm
    width: 100%

  &__info-item
    margin-bottom: variables.$m-xs
    word-break: break-all

    &.-label
      .title
        color: variables.$color-black
        font-size: variables.$font-size-sm
        font-weight: variables.$font-weight-bold

      .value
        color: variables.$color-gray-dark
        margin-left: variables.$m-xs

  &__actions
    display: flex
    flex-direction: column
    margin-top: auto
    width: 100%

  &__action
    flex: 1 1 100%
    margin: variables.$m-xs / 2 0

  &__switch-ir-service
    margin: variables.$m-xs
    max-width: 500px
+mixins.media-up(variables.$grid-sm)
  .brand

    &__card
      align-items: initial
      display: flex
      flex-direction: row
      flex: 0 0 500px
      justify-content: space-between
      padding: variables.$m

      .safe-image
        height: variables.$thumb-size
        margin: 0 variables.$m 0 0
        width: variables.$thumb-size

    &__details
      align-items: initial
      flex: 1 1 auto
      padding: 0

    &__type
      top: variables.$thumb-size - variables.$m-xs

    &__actions
      display: flex
      flex-direction: row
      flex-wrap: wrap
      justify-content: flex-start
      margin-top: auto
      width: 100%

    &__action
      flex: 1 1 100%
      margin-bottom: variables.$m-xs / 2
+mixins.media-up(variables.$grid-md)
  .brands
    &__container
      margin-left: auto
      margin-right: auto
      width: 70%

    &__item
      flex-direction: row

    &__name
      margin-left: variables.$m-sm

  .brand
    &__container
      margin-left: auto
      margin-right: auto
      width: 70%

    &__actions
      display: flex
      flex-direction: row
      margin-top: auto
      width: 100%

    &__action
      flex: 0 1 23%
      margin-right: variables.$m-xs
+mixins.media-up(variables.$grid-xl)
  .brand

    &__card
      margin: variables.$m-xs
