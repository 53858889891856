@use "../base/mixins"
@use "../base/variables"

.csv-import
  display: block
  padding: variables.$m
  padding-top: 0
  text-align: center

  &__drop

    &:focus
      outline: none !important

+mixins.media-up(variables.$grid-sm)
  .csv-import

    &__status
      width: 100%