@use "sass:color"
@use "../../base/mixins"
@use "../../base/variables"

.reference-images
  &__container
    display: flex
    flex-wrap: wrap
    justify-content: center

    .reference-image__card

      &:hover
        background-color: color.adjust(variables.$color-gray-light, $lightness: -10)
        opacity: 0.8

      &.-active
        background-color: color.adjust(variables.$color-gray-light, $lightness: -20)
  
.reference-image
  &__container

  &__card
    align-items: center
    background-color: variables.$color-white
    border-radius: variables.$border-radius
    box-shadow: variables.$box-shadow-simple
    flex: 0 0 290px
    margin-bottom: variables.$m
    overflow: hidden
    position: relative

    .safe-image
      box-shadow: variables.$box-shadow-simple
      height: variables.$thumb-size
      margin: 0 auto
      width: 100%
      min-width: variables.$thumb-size
  
  &__details
    align-items: center
    display: flex
    flex-direction: column
    padding: variables.$m-sm

  &__type
    background-color: variables.$brand-additional
    border-radius: 0 variables.$border-radius variables.$border-radius 0
    color: variables.$color-white
    font-size: variables.$font-size-sm
    font-weight: 100
    left: 0
    padding: 0 variables.$m-sm
    position: absolute
    top: variables.$thumb-size - variables.$m-lg * 0.75
    text-transform: uppercase

    &.-pvs
      background-color: variables.$brand-secondary

    &.-personal_experience
      background-color: variables.$brand-third
  
  &__info
    margin-bottom: variables.$m-sm
    width: 100%

  &__info-item
    color: variables.$color-gray-dark
    margin-bottom: variables.$m-xs
    word-break: break-all

    &.-label
      .title
        color: variables.$color-black
        font-size: variables.$font-size-sm

      .value
        color: variables.$color-gray-dark
        margin-left: variables.$m-xs

        a
          font-size: variables.$font-size-sm

    &.-datetime
      color: variables.$color-gray
      font-size: variables.$font-size-xs
      margin-top: 0

  &__actions
    display: flex
    flex-direction: column
    margin-top: auto
    width: 100%

    a, button
      flex: 1 1 50%
      margin: variables.$m-xs / 2 0

    &.-wrap
      flex-wrap: wrap

      a,button
        flex: 1 1 50%

+mixins.media-up(variables.$grid-sm)
  .reference-image

    &__card
      align-items: initial
      display: flex
      flex-direction: row
      flex: 0 0 500px
      justify-content: space-between
      padding: variables.$m

      .safe-image
        height: variables.$thumb-size
        margin: 0 variables.$m 0 0
        width: variables.$thumb-size
        
    &__details
      align-items: initial
      flex: 1 1 auto
      padding: 0

    &__type
      top: variables.$thumb-size - variables.$m-xs

    &__actions
      display: flex
      flex-direction: row
      justify-content: flex-start
      margin-top: auto
      width: 100%

      a, button
        margin-bottom: 0

        &:not(:last-child)
          margin-right: variables.$m-xs 

      &.-wrap
        a, button
          flex: 0 1 100%
          margin-right: 0

+mixins.media-up(variables.$grid-md)
  .reference-image

    &__actions
      display: flex
      flex-direction: row
      margin-top: auto
      width: 100%

      a, button
        margin-bottom: 0

        &:not(:last-child)
          margin-right: variables.$m-xs 

      &.-wrap
        a, button
          flex: 0 1 48%

+mixins.media-up(variables.$grid-lg)
  .reference-image
    &__container
      margin-left: auto
      margin-right: auto
      width: 50%

    &__card
      margin: variables.$m-xs

    &__actions
      a, button
        flex: 0 1 70px
