@use "../base/variables"

.safe-image-with-blur
  height: variables.$thumb-size
  width: variables.$thumb-size
  position: relative
  overflow: hidden

  &.-background
    filter: blur(3px) brightness(80%)
    height: 104%
    margin-left: -2%
    margin-top: -2%
    object-fit: cover
    min-width: 104%
    position: absolute

  &.-foreground
    top: 50%
    left: 50%
    position: absolute
    object-fit: contain
    transform: translate(-50%, -50%)
