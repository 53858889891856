@use "../base/variables"

.collapse
  &__toggle
    color: variables.$color-gray-dark
    cursor: pointer
    border: 1px solid variables.$color-gray-dark
    border-radius: variables.$border-radius-sm
    padding: 0 variables.$m-xs

  &__toggle-icon
    height: variables.$font-size-base
    margin-left: variables.$m-xs
    width: variables.$font-size-base
