
@media screen and (min-width: 720px) 
  .form-container
    margin: auto
    max-width: 400px

.form-error 
  color: #dc3545
  margin-bottom: 1rem

.datetime-picker
  flex: 1
  -webkit-appearance: none
  & input
    border-top-right-radius: 0
    border-bottom-right-radius: 0
