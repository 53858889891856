@use "../../base/mixins"
@use "../../base/variables"

.bulk-actions
  &__container
    display: flex
    flex-direction: column
    justify-content: space-between
    align-items: center
    margin: variables.$m-xs 0
    padding: variables.$m-xs 0
    
  &__info
    display: flex
    margin-bottom: variables.$m-xs
    width: 100%

  &__actions
    display: flex
    flex-direction: column
    width: 100%

    a, button
      margin: 0 0 variables.$m-xs 0

+mixins.media-up(variables.$grid-sm)
  .bulk-actions
    &__actions
      display: flex
      justify-content: center
      flex-direction: row

      a, button
        margin: 0 variables.$m-xs 0 0


+mixins.media-up(variables.$grid-md)
  .bulk-actions
    &__container
      flex-direction: row

    &__actions
      justify-content: flex-end
